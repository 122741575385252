import React from 'react';
import '../../../scss/svg.scss';
import { connect } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { AppState } from '../../../redux/reducers';
import { Row, Col } from '../../common/components/elements';
import { ROUTES } from '../../../configs/routes';
import Link from '../../common/components/Link';
import DialogChangePassword from './DialogChangePassword';
import _ from 'lodash';

const mapStateToProps = (state: AppState) => {
  return {
    userData: state.account.userData,
    router: state.router,
  };
};
interface Props extends ReturnType<typeof mapStateToProps> { }

const AccountInfoDesktop: React.FunctionComponent<Props> = props => {
  const { userData, router } = props;
  const [open, setOpen] = React.useState(false);
  if (!userData) {
    return null;
  }
  return (
    <div style={{ marginTop: 32 }}>
      <Row>
        <Col style={{ marginRight: 72 }}>
          <Typography style={{ height: 28 }} variant="body2">
            <FormattedMessage id="managerAccount.email" />
          </Typography>
          <Typography style={{ height: 28 }} variant="body2">
            <FormattedMessage id="managerAccount.userName" />
          </Typography>
          <Typography style={{ height: 28 }} variant="body2">
            <FormattedMessage id="managerAccount.phoneNumber" />
          </Typography>
          <Typography style={{ height: 28 }} variant="body2">
            <FormattedMessage id="managerAccount.codeRule" />
          </Typography>
        </Col>
        <Col>
          <Typography style={{ height: 28 }} variant="body2">
            {userData.email}
          </Typography>
          <Typography style={{ height: 28 }} variant="subtitle2">
            {userData.name}
          </Typography>
          <Typography style={{ height: 28 }} variant="body2">
            {userData.phoneNumber}
          </Typography>
          <Typography style={{ height: 28 }} variant="body2">
            {userData.roleGroup.roleName}
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: 32 }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          style={{ marginRight: 32 }}
          onClick={() => setOpen(true)}
        >
          <Typography variant="button">
            <FormattedMessage id="managerAccount.changePassword" />
          </Typography>
        </Button>
        <Link
          to={{
            pathname: ROUTES.managerAccount.updateAccountInfo,
            state: { ..._.get(router, 'location.state', {}), [`${router.location.pathname}`]: true },
          }}
        >
          <Button disableElevation variant="contained" color="secondary">
            <Typography variant="button">
              <FormattedMessage id="managerAccount.updateInfo" />
            </Typography>
          </Button>
        </Link>
      </Row>
      <DialogChangePassword open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default connect(mapStateToProps)(AccountInfoDesktop);
