import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import { fetchThunk } from '../../../../common/redux/thunk';
import { removeNullParams } from '../../../constants';
import {
  IBannerGroupSearchParams,
  defaultBannerGroupSearchParams,
  IBannerGroup,
} from '../../../../common/models/banner';

export interface ManagerBannerGroupState {
  data?: IBannerGroup[];
  loading: boolean;
  bannerGroupDetail?: IBannerGroup;
  params: IBannerGroupSearchParams;
}

export const setData = createAction(
  'smartPos/managerBannerGroup/setData',
  (data?: IBannerGroup[]) => ({
    data,
  }),
)();

export const setLoading = createAction(
  'smartPos/managerBannerGroup/setLoading',
  (val: boolean) => ({
    val,
  }),
)();

export const setParams = createAction(
  'smartPos/managerBannerGroup/setParams',
  (val: IBannerGroupSearchParams) => ({
    val,
  }),
)();

export const setBannerGroupDetail = createAction(
  'smartPos/managerBannerGroup/setBannerGroupDetail',
  (data?: IBannerGroup) => ({
    data,
  }),
)();

export function fetchBannerGroup(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { params } = state.smartPosSo.managerBanner.managerBannerGroup;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getBannerGroup, 'post', JSON.stringify(removeNullParams(params))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}
export function deActivatebanner(
  bannerGroup: IBannerGroup,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.deleteBannerGroup, 'post', JSON.stringify(bannerGroup)),
    );
    return json;
  };
}

const actions = {
  setDataBannerGroup: setData,
  setFilter: setParams,
  setLoading,
  setBannerGroupDetail,
};

export const DEFAULT_MANAGER_BANNER_GROUP_STATE: ManagerBannerGroupState = {
  params: defaultBannerGroupSearchParams,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerBannerGroupState = DEFAULT_MANAGER_BANNER_GROUP_STATE,
  action: ActionT,
): ManagerBannerGroupState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setParams):
      return { ...state, params: action.payload.val };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    case getType(setBannerGroupDetail):
      return { ...state, bannerGroupDetail: action.payload.data };
    default:
      return state;
  }
}
