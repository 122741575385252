import { ButtonBase, withStyles } from '@material-ui/core';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from 'react-redux';
import { ASIDE_BACKGROUND, DARK_GREY, SECONDARY, WHITE } from '../../configs/colors';
import { ROUTES_TAB } from '../../configs/routes';
import { RoutesTabType } from '../../constants';
import { AppState } from '../../redux/reducers';
import '../../scss/svg.scss';
import { ReactComponent as IconBack } from '../../svg/ic_back_actionbar.svg';
import { ReactComponent as IconMenu } from '../../svg/ic_menu.svg';
import { ASIDE_MIN_WIDTH, ASIDE_WIDTH, HEADER_HEIGHT } from '../constants';
import { getListRoutesContain } from '../utils';
import DefaultAsideItems from './DefaultAsideItems';

export const ButtonRow = withStyles(theme => ({
  root: {
    '&:hover': {
      background: DARK_GREY,
    },
    height: '48px',
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: ASIDE_WIDTH,
    textAlign: 'start',
  },
}))(ButtonBase);

const mapStateToProps = (state: AppState) => {
  return { router: state.router, userData: state.account.userData };
};
interface Props extends ReturnType<typeof mapStateToProps> {
  open: boolean;
  onClose(): void;
}

const DefaultAside: React.FunctionComponent<Props> = props => {
  const { router, open, onClose, userData } = props;
  const { pathname } = router.location;
  const [hoverOpen, setOpen] = React.useState(false);

  const getListRouterActive = React.useMemo(() => {
    return getListRoutesContain(ROUTES_TAB, router.location.pathname);
  }, [router.location.pathname]);

  return (
    <>
      <div
        style={{
          minWidth: open ? ASIDE_WIDTH : ASIDE_MIN_WIDTH,
          transition: 'all 0.3s',
        }}
      />
      <div
        style={{
          width: open || hoverOpen ? ASIDE_WIDTH : ASIDE_MIN_WIDTH,
          overflow: 'hidden',
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          transition: 'width 0.3s',
          position: 'fixed',
          top: 64,
          left: 0,
          flexShrink: 0,
          boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 1px rgba(0, 0, 0, 0.1)',
          background: open || hoverOpen ? ASIDE_BACKGROUND : WHITE,
          zIndex: 1000,
        }}
      >
        <ButtonRow
          style={{
            justifyContent: open || hoverOpen ? 'flex-end' : 'center',
            padding: open || hoverOpen ? '0px 20px' : 0,
            minWidth: '100%',
          }}
          onClick={onClose}
        >
          {open || hoverOpen ? (
            <IconBack className="svgFillAll" style={{ stroke: WHITE, width: 32, height: 32 }} />
          ) : (
            <IconMenu className="svgFillAll" style={{ stroke: SECONDARY, width: 32, height: 32 }} />
          )}
        </ButtonRow>
        <PerfectScrollbar
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => setOpen(false)}
        >
          <div
            style={{
              height: `calc(100%-64px)`,
              marginBottom: 48,
            }}
          >
            {userData &&
              ROUTES_TAB.map((v: RoutesTabType, index: number) => (
                <DefaultAsideItems
                  key={index}
                  userData={userData}
                  open={open || hoverOpen}
                  data={v}
                  pathname={pathname}
                  listRouterActive={getListRouterActive}
                />
              ))}
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(DefaultAside);
