import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../redux/reducers';
import { getGeneralLocale } from '../redux/reducer';
import { fetchMangeDeviceData } from '../../smart-pos-so/configurationDevice/managerStatus/redux/managerStatusReducer';

function mapStateToProps(state: AppState) {
  return {};
}

interface Props extends React.PropsWithChildren<ReturnType<typeof mapStateToProps>> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

const InitData: React.FC<Props> = props => {
  const { dispatch, children } = props;

  useEffect(() => {
    // dispatch(validateAccessToken(true));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getGeneralLocale());
    dispatch(fetchMangeDeviceData());
  }, [dispatch]);

  return <>{children}</>;
};

export default connect(mapStateToProps)(InitData);
