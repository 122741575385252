import { Container, useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { BACKGROUND } from '../../configs/colors';
import { MUI_THEME } from '../../configs/setupTheme';
import { Col, PageWrapper, Row } from '../../modules/common/components/elements';
import InitData from '../../modules/common/components/InitData';
import LoadingIcon from '../../modules/common/components/LoadingIcon';
import { AppState } from '../../redux/reducers';
import { HEADER_HEIGHT } from '../constants';
import { getListRoutesActivate, flatRoutes } from '../utils';
import DefaultAside from './DefaultAside';
import DefaultBreadcrumbs from './DefaultBreadcrumbs';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { ROUTES_TAB, ROUTES } from '../../configs/routes';
import NotFound from '../../modules/common/components/NotFound';
import AccountInfo from '../../modules/account/pages/AccountInfo';

const mapStateToProps = (state: AppState) => {
  return { router: state.router, userData: state.account.userData };
};
interface Props extends ReturnType<typeof mapStateToProps> {}

const DefaultLayout: React.FunctionComponent<Props> = props => {
  const { router, userData } = props;
  const [openSideBar, setOpenSideBar] = React.useState(true);
  const matches = useMediaQuery(MUI_THEME.breakpoints.up('md'));

  React.useEffect(() => {
    setOpenSideBar(matches);
  }, [matches]);

  const listRoutes = React.useMemo(() => {
    return getListRoutesActivate(userData?.roleGroup?.listFeature, flatRoutes(ROUTES_TAB));
  }, [userData]);

  return (
    <PageWrapper>
      <DefaultHeader />
      <Row
        style={{ flex: 1, alignItems: 'flex-start', position: 'relative', background: BACKGROUND }}
      >
        <DefaultAside
          open={openSideBar}
          onClose={() => {
            setOpenSideBar(!openSideBar);
          }}
        />
        <Col
          style={{
            flex: 1,
            minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
            overflow: 'hidden',
          }}
        >
          <Container
            style={{
              paddingTop: 16,
              maxWidth: 'none',
              flex: 1,
              padding: '16px 24px 24px 32px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DefaultBreadcrumbs />
            <InitData>
              <React.Suspense fallback={<LoadingIcon style={{ marginTop: 240 }} />}>
                <Switch location={router.location}>
                  {listRoutes.map(
                    (route, index) =>
                      route.component && (
                        <Route
                          key={route.name}
                          exact={route.exact}
                          path={route.path}
                          component={route.component}
                        />
                      ),
                  )}
                  <Redirect to={ROUTES.managerAccount.accountInfo} />
                  <Route component={NotFound} />
                </Switch>
              </React.Suspense>
            </InitData>
          </Container>
          <DefaultFooter />
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(DefaultLayout);
