import { Button, Dialog, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'typesafe-actions';
import voca from 'voca';
import { API_PATHS } from '../../../configs/API';
import { GREY } from '../../../configs/colors';
import { SUCCESS_CODE, noBlankSpaceRegex } from '../../../constants';
import { AppState } from '../../../redux/reducers';
import { ReactComponent as IconEyeClose } from '../../../svg/ic_eye_close.svg';
import { ReactComponent as IconEyeOpen } from '../../../svg/ic_eye_open.svg';
import { ReactComponent as IconLock } from '../../../svg/ic_lock.svg';
import { logout } from '../../auth/redux/authThunks';
import { Col, Row, snackbarSetting } from '../../common/components/elements';
import { FreeTextField } from '../../common/components/Form';
import { fetchThunk } from '../../common/redux/thunk';
import {
  defaultParamsChangePassword,
  defaultParamsChangePasswordValidation,
  ParamsChangePassword,
  ValidationParamsChangePassword,
  validationParamsChangePassword,
  validParamsChangePassword,
} from '../utils';

interface Props {
  open: boolean;
  onClose(): void;
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

const DialogChangePassword = (props: Props) => {
  const { open, onClose, dispatch } = props;
  const [params, setParams] = React.useState<ParamsChangePassword>(defaultParamsChangePassword);
  const [validation, setValidation] = React.useState<ValidationParamsChangePassword>(
    defaultParamsChangePasswordValidation,
  );
  const [seePass, setSeePass] = React.useState(false);
  const [seeRePass, setSeRePass] = React.useState(false);
  const [ticktock, setTick] = React.useState(false);
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const changePassword = React.useCallback(async () => {
    const json = await dispatch(fetchThunk(API_PATHS.changePass, 'post', JSON.stringify(params)));
    if (json?.code === SUCCESS_CODE) {
      dispatch(logout());
      enqueueSnackbar(
        'managerAccount.changePasswordSuccess',
        snackbarSetting(
          key => closeSnackbar(key),
          intl.formatMessage({ id: 'managerAccount.changePasswordSuccess' }),
        ),
      );
    } else {
      json?.message &&
        enqueueSnackbar(
          json?.message,
          snackbarSetting(key => closeSnackbar(key), json?.message),
        );
    }
  }, [closeSnackbar, dispatch, enqueueSnackbar, intl, params]);

  const validateParams = React.useCallback(() => {
    const valid = validationParamsChangePassword(params);
    setValidation(valid);
    const errorMessage = validParamsChangePassword(valid);
    setTick(one => !one);
    if (errorMessage) {
      enqueueSnackbar(
        errorMessage,
        snackbarSetting(key => closeSnackbar(key), intl.formatMessage({ id: errorMessage })),
      );
    } else {
      changePassword();
    }
  }, [changePassword, closeSnackbar, enqueueSnackbar, intl, params]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { borderRadius: 38, padding: '16px 24px', minWidth: 600 } }}
      onEnter={() => {
        setParams(defaultParamsChangePassword);
        setValidation(defaultParamsChangePasswordValidation);
      }}
    >
      <Col key={`${ticktock}`}>
        <Typography variant="h5">
          <FormattedMessage id="managerAccount.changePassword" />
        </Typography>

        <FreeTextField
          style={{ marginTop: 24, marginRight: 0 }}
          header={intl.formatMessage({ id: 'oldPassword' })}
          placeholder={intl.formatMessage({ id: 'initPassword' })}
          text={params.oldPass}
          valid={validation.oldPass}
          update={text => {
            setParams({ ...params, oldPass: voca.latinise(text) });
            setValidation({ ...validation, oldPass: true });
          }}
          inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
          type="password"
          endAdornment={
            <InputAdornment position="end" style={{ marginRight: 8 }}>
              <IconLock />
            </InputAdornment>
          }
          regex={noBlankSpaceRegex}
        />
        <FreeTextField
          style={{ marginTop: 24, marginRight: 0 }}
          header={intl.formatMessage({ id: 'newPassword' })}
          placeholder={intl.formatMessage({ id: 'initNewPassword' })}
          text={params.newPass}
          valid={validation.newPass}
          update={text => {
            setParams({ ...params, newPass: voca.latinise(text) });
            setValidation({ ...validation, newPass: true });
          }}
          inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
          type={seePass ? 'text' : 'password'}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginRight: 8 }}
              onClick={() => setSeePass(!seePass)}
            >
              <IconButton style={{ padding: 0 }}>
                {seePass ? <IconEyeClose /> : <IconEyeOpen />}
              </IconButton>
            </InputAdornment>
          }
          regex={noBlankSpaceRegex}
        />
        <FreeTextField
          style={{ marginTop: 24, marginRight: 0 }}
          header={intl.formatMessage({ id: 'verifyPassword' })}
          placeholder={intl.formatMessage({ id: 'verifyPassword' })}
          text={params.verifyPass}
          valid={validation.verifyPass}
          update={text => {
            setParams({ ...params, verifyPass: voca.latinise(text) });
            setValidation({ ...validation, verifyPass: true });
          }}
          inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
          type={seeRePass ? 'text' : 'password'}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ marginRight: 8 }}
              onClick={() => setSeRePass(!seeRePass)}
            >
              <IconButton style={{ padding: 0 }}>
                {seeRePass ? <IconEyeClose /> : <IconEyeOpen />}
              </IconButton>
            </InputAdornment>
          }
          regex={noBlankSpaceRegex}
        />
        <Row style={{ marginTop: 48, justifyContent: 'center' }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            size="large"
            style={{ marginRight: 24, minWidth: 144 }}
            onClick={validateParams}
          >
            <Typography variant="button">
              <FormattedMessage id="confirm" />
            </Typography>
          </Button>
          <Button
            disableElevation
            variant="contained"
            size="large"
            style={{ minWidth: 144, background: GREY, color: 'white' }}
            onClick={onClose}
          >
            <Typography variant="button">
              <FormattedMessage id="cancel" />
            </Typography>
          </Button>
        </Row>
      </Col>
    </Dialog>
  );
};

export default connect()(DialogChangePassword);
