import { InputAdornment, Typography } from '@material-ui/core';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import voca from 'voca';
import { DARK_BLUE, RED } from '../../../../configs/colors';
import { GAMI, invalidUserNameRegex, noBlankSpaceRegex } from '../../../../constants';
import { AppState } from '../../../../redux/reducers';
import { ReactComponent as IconLock } from '../../../../svg/ic_lock.svg';
import { ReactComponent as IconProfile } from '../../../../svg/ic_profile.svg';
import { Col, Row } from '../../../common/components/elements';
import { FreeTextField } from '../../../common/components/Form';
import LoadingButton from '../../../common/components/LoadingButton';
import { setLoginErrorMsg } from '../../redux/authReducer';
import { login } from '../../redux/authThunks';

import {
  defaultParamsLogin,
  defaultParamsLoginValidation,
  ParamsLogin,
  ParamsLoginValidation,
  Phase,
  validationLogin,
  validLogin,
} from '../../utils';

const mapState2Props = (state: AppState) => {
  return {
    authenticating: state.auth.authenticating,
    loginErrorMsg: state.auth.loginErrorMsg,
  };
};
export interface Props extends WrappedComponentProps, ReturnType<typeof mapState2Props> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
  onChangePage(page: Phase): void;
  style?: React.CSSProperties;
}

const LoginComponent: React.FunctionComponent<Props> = props => {
  const { dispatch, authenticating, loginErrorMsg, intl, onChangePage, style } = props;
  const [paramLogin, setParams] = React.useState<ParamsLogin>(defaultParamsLogin);
  const [validation, setValidation] = React.useState<ParamsLoginValidation>(
    defaultParamsLoginValidation,
  );
  const [ticktock, setTick] = React.useState(true);

  const logIn = React.useCallback(
    async (e: any) => {
      e.preventDefault();
      const valid = validationLogin(paramLogin);
      setValidation(valid);
      setTick(one => !one);
      const errorMes = validLogin(valid);
      if (errorMes) {
        dispatch(setLoginErrorMsg(intl.formatMessage({ id: errorMes })));
        return;
      }
      dispatch(login(paramLogin));
    },
    [dispatch, intl, paramLogin],
  );

  React.useEffect(() => {
    setParams(defaultParamsLogin);
    setValidation(defaultParamsLoginValidation);
    dispatch(setLoginErrorMsg());
  }, [dispatch]);

  return (
    <Row style={style}>
      <Col style={{ minWidth: 494, minHeight: 490, objectFit: 'cover' }}>
        <Col style={{ padding: '90px 42px' }}>
          <Typography variant="h3" style={{ fontWeight: 'normal', textAlign: 'center' }}>
            <FormattedMessage id="login" />
          </Typography>
          <form onSubmit={logIn} key={`${ticktock}`}>
            <FreeTextField
              style={{ marginTop: 24, marginRight: 0 }}
              header={intl.formatMessage({ id: 'userName' })}
              placeholder={intl.formatMessage({ id: 'userNameEx' })}
              text={paramLogin.username}
              update={username => {
                !invalidUserNameRegex.test(username) &&
                  setParams({ ...paramLogin, username: voca.latinise(username) });
                setValidation({ ...validation, username: true });
              }}
              valid={validation.username && validation.usernameValid}
              inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
              endAdornment={
                <InputAdornment position="end" style={{ marginRight: 8 }}>
                  <IconProfile />
                </InputAdornment>
              }
              optional
            />
            <FreeTextField
              style={{ marginTop: 28, marginRight: 0 }}
              header={intl.formatMessage({ id: 'password' })}
              placeholder="******"
              text={paramLogin.password}
              update={password => {
                setParams({ ...paramLogin, password: voca.latinise(password) });
                setValidation({ ...validation, password: true, passwordValid: true });
              }}
              valid={validation.password && validation.passwordValid}
              inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
              type="password"
              endAdornment={
                <InputAdornment position="end" style={{ marginRight: 8 }}>
                  <IconLock />
                </InputAdornment>
              }
              optional
              regex={noBlankSpaceRegex}
            />
            <div style={{ textAlign: 'center' }}>
              <div style={{ padding: '5px', color: RED, marginBottom: '-8px' }}>
                <Typography variant="body2">
                  {loginErrorMsg ? <span>{loginErrorMsg}</span> : <>&nbsp;</>}
                </Typography>
              </div>
              <LoadingButton
                size="large"
                type="submit"
                style={{ width: '260px', marginTop: '15px' }}
                variant="contained"
                color="secondary"
                loading={authenticating}
                onClick={logIn}
              >
                <FormattedMessage id="login" />
              </LoadingButton>
              <Row
                style={{
                  color: DARK_BLUE,
                  justifyContent: 'center',
                  marginTop: 16,
                }}
              >
                <Typography variant="body2">
                  <FormattedMessage id="forgotPassword" />? &nbsp;
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => onChangePage('forgotPassword')}
                >
                  <FormattedMessage id="clickHere" />
                </Typography>
              </Row>
            </div>
          </form>
        </Col>
      </Col>
      <img
        src={GAMI ? '/images/ic_banner_gami.png' : '/images/ic_banner.png'}
        alt=""
        style={{ height: 550, objectFit: 'contain' }}
      />
    </Row>
  );
};

export default connect(mapState2Props)(injectIntl(LoginComponent));
