import { ROUTES_TAB } from '../configs/routes';
import { Role, RoutesTabType, some } from '../constants';

export function flatRoutes(list: RoutesTabType[]): RoutesTabType[] {
  let listTerm: RoutesTabType[] = [];
  list.forEach(route => {
    if (route.subMenu) {
      listTerm = listTerm.concat(flatRoutes(route.subMenu));
    }
    if (route.hiddenMenu) {
      listTerm = listTerm.concat(flatRoutes(route.hiddenMenu));
    }
    if (route.path) {
      listTerm = listTerm.concat(route);
    }
  });
  return listTerm;
}

function getParentRoutes(list: RoutesTabType[], hashMap: some[], parent?: RoutesTabType) {
  list.forEach(route => {
    if (route.component) {
      hashMap.push({ child: route, parent: route });
    }
    if (parent) {
      hashMap.push({ child: route, parent });
    }
    if (route.subMenu) {
      getParentRoutes(route.subMenu, hashMap, route);
    }
    if (route.hiddenMenu) {
      getParentRoutes(route.hiddenMenu, hashMap, route);
    }
  });
}

function getHistoryPath(list: RoutesTabType[], hashMap: some[], pathName: string) {
  hashMap.forEach((obj: some, index: number) => {
    const isConstant = list.findIndex(one => one?.name === obj.parent?.name);
    if (obj.child.path === pathName && isConstant === -1) {
      list.push(obj.parent);
      if (!obj.parent.isModule) {
        getHistoryPath(list, hashMap, obj.parent?.path);
      }
    }
  });
}

export function getListRoutesContain(list: RoutesTabType[], pathName: string): some[] {
  const listRouter: RoutesTabType[] = [];
  const hashMap: some[] = [];
  getParentRoutes(list, hashMap);
  getHistoryPath(listRouter, hashMap, pathName);
  listRouter.forEach((route: RoutesTabType, index: number) => {
    listRouter[index] = { ...route, backStep: index };
  });
  return listRouter;
}

export function getCurrentRoute(pathName: string) {
  const listRoutes = flatRoutes(ROUTES_TAB);
  return listRoutes.find((route: RoutesTabType) => route.path === pathName);
}

/* ---------------Permission--------------*/

export function hasPermission(routePermission: some[], listRole?: Role[]) {
  const roles: some[] = [];
  listRole &&
    listRole.forEach((role: Role) => {
      routePermission?.forEach((per: some) => {
        if (role.module.includes(per.module) && per[`${role.role}`]) {
          roles.push(per);
        }
      });
    });
  return listRole ? listRole.length === roles.length : true;
}

export function getListRoutesActivate(routePermission: some[], listRoutes: RoutesTabType[]) {
  const list: RoutesTabType[] = [];
  listRoutes &&
    listRoutes.forEach((route: RoutesTabType) => {
      if (route.path) {
        if (route.listRole) {
          if (hasPermission(routePermission, route.listRole)) {
            list.push(route);
          }
        } else {
          list.push(route);
        }
      }
    });
  return list;
}

export function getCurrentRole(routePermission: some[], roles?: Role[] | string) {
  const listRouteActive = flatRoutes(ROUTES_TAB);
  if (roles) {
    if (typeof roles === 'string') {
      const currentRoute = listRouteActive.find(v => v.path === roles);
      return hasPermission(routePermission, currentRoute?.listRole);
    }
    return hasPermission(routePermission, roles);
  }
  return true;
}
