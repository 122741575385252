import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import moment from 'moment';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import { fetchThunk } from '../../../../common/redux/thunk';
import { removeNullParams } from '../../../constants';
import { defaultParamsManagerErrorDevice, ParamsErrorDevice } from '../utils';
import { API_PATHS } from '../../../../../configs/API';
import { DATE_FORMAT, DATE_FORMAT_BACKEND, TIME_FORMAT } from '../../../../common/constants';

export interface managerErrorState {
  data?: some;
  params: ParamsErrorDevice;
  loading: boolean;
}

export const setData = createAction('smartPos/managerErrorDevice/setData', (data?: some) => ({
  data,
}))();

export const setParams = createAction(
  'smartPos/managerErrorDevice/setParams',
  (params: ParamsErrorDevice) => ({
    params,
  }),
)();

export const setLoading = createAction(
  'smartPos/managerErrorDevice/setLoading',
  (val: boolean) => ({
    val,
  }),
)();

export function fetchMangerErrorData(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { params } = state.smartPosSo.configurationDevice.managerError;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(
        API_PATHS.getDeviceLogError,
        'post',
        JSON.stringify(
          removeNullParams({
            ...params,
            date: moment(params.date, DATE_FORMAT).format(DATE_FORMAT_BACKEND),
            fromTime: moment(params.fromTime, TIME_FORMAT).isValid() ? params.fromTime : undefined,
            toTime:
              moment(params.fromTime, TIME_FORMAT).isValid() &&
              moment(params.toTime, TIME_FORMAT).isValid()
                ? params.toTime
                : undefined,
          }),
        ),
      ),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

const actions = { setData, setParams, setLoading };
export const DEFAULT_MANAGER_ERROR_STATE: managerErrorState = {
  params: {
    ...defaultParamsManagerErrorDevice,
    date: moment().format(DATE_FORMAT),
    fromTime: moment()
      .subtract(3, 'hours')
      .format(TIME_FORMAT),
    toTime: moment().format(TIME_FORMAT),
  },
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: managerErrorState = DEFAULT_MANAGER_ERROR_STATE,
  action: ActionT,
): managerErrorState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setParams):
      return { ...state, params: action.payload.params };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
