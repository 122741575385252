import { PAGE_SIZE } from '../../../../constants';

export interface ParamsErrorDevice {
  deviceId: string;
  date: string;
  fromTime: string;
  toTime: string;
  validation: boolean;
  pageSize: number;
  pageOffset: number;
}

export const defaultParamsManagerErrorDevice: ParamsErrorDevice = {
  deviceId: '',
  date: '',
  fromTime: '',
  toTime: '',
  validation: true,
  pageSize: PAGE_SIZE,
  pageOffset: 0,
};

export function syntaxHighlight(data: any) {
  let json;
  if (typeof json !== 'string') {
    json = JSON.stringify(data, undefined, 2);
  }
  json = data
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (match: any) => {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );
}
