import { AppBar } from '@material-ui/core';
import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'typesafe-actions';
import { GREY } from '../../configs/colors';
import { GAMI } from '../../constants';
import Badge from '../../modules/account/component/Badge';
import { Row } from '../../modules/common/components/elements';
import Link from '../../modules/common/components/Link';
import { AppState } from '../../redux/reducers';
import { HEADER_HEIGHT } from '../constants';

const mapStateToProps = (state: AppState) => {
  return { router: state.router };
};
interface Props extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

const DefaultHeader: React.FunctionComponent<Props> = props => {
  return (
    <AppBar
      position="sticky"
      style={{
        height: HEADER_HEIGHT,
        background: GAMI ? 'white' : GREY,
        color: GAMI ? 'black' : 'white',
      }}
      elevation={GAMI ? 1 : 0}
    >
      <Row style={{ height: '100%', paddingRight: '16px' }}>
        <Link
          to="/"
          style={{
            marginLeft: '76px',
            transition: 'width 0.3s',
            justifyContent: 'center',
          }}
        >
          <Row>
            <img
              src={GAMI ? '/images/logo_gami.png' : '/images/logo_sunworld.png'}
              alt=""
              style={{ maxHeight: GAMI ? 48 : 28 }}
            />
            <img src="/images/logo_vnpay.png" alt="" style={{ marginLeft: '12px', maxWidth: 80 }} />
          </Row>
        </Link>
        <Row style={{ flex: 1, justifyContent: 'flex-end', marginRight: 24 }}>
          <Badge />
        </Row>
      </Row>
    </AppBar>
  );
};

export default connect(mapStateToProps)(DefaultHeader);
