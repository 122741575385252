import { Button, ButtonBase, Collapse, Divider, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DARK_GREY, GREY, PRIMARY, SECONDARY } from '../../../configs/colors';
import { ROUTES } from '../../../configs/routes';
import { GAMI } from '../../../constants';
import { HEADER_HEIGHT } from '../../../layout/constants';
import { AppState } from '../../../redux/reducers';
import { ReactComponent as IconAvatar } from '../../../svg/ic_avatar.svg';
import { ReactComponent as IconLogout } from '../../../svg/ic_logout.svg';
import { ReactComponent as IconPerson } from '../../../svg/ic_person.svg';
import { logout } from '../../auth/redux/authThunks';
import { Col } from '../../common/components/elements';
import Link from '../../common/components/Link';

const mapStateToProps = (state: AppState) => ({
  userData: state.account.userData,
  router: state.router,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

const UserInfoDropdown: React.FunctionComponent<Props> = props => {
  const { dispatch, userData, router } = props;
  const [open, setOpen] = React.useState(false);

  const ref = React.useRef<HTMLDivElement | null>(null);

  const onBlur = React.useCallback((e: React.FocusEvent<HTMLDivElement>) => {
    if (e.relatedTarget instanceof Element) {
      if (e.currentTarget.contains(e.relatedTarget as Element)) {
        return;
      }
    }

    setOpen(false);
  }, []);

  return (
    <>
      {userData && (
        <div
          ref={ref}
          tabIndex={-1}
          onBlur={onBlur}
          style={{
            outline: 'none',
            minWidth: 212,
          }}
        >
          <ButtonBase
            style={{
              color: 'inherit',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              cursor: 'pointer',
              background: open ? fade(DARK_GREY, 0.5) : undefined,
              height: HEADER_HEIGHT,
              width: '100%',
              padding: '0px 16px',
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {userData.photo ? (
              <img
                style={{
                  borderRadius: '50%',
                  height: '48px',
                  width: '48px',
                  objectFit: 'cover',
                }}
                src={userData.photo}
                alt=""
              />
            ) : (
              <IconAvatar
                className="svgFillAll"
                style={{
                  borderRadius: '50%',
                  height: '48px',
                  width: '48px',
                  objectFit: 'cover',
                  fill: GAMI ? SECONDARY : 'white',
                }}
              />
            )}
            <Col style={{ marginLeft: '12px', flex: 1, textAlign: 'start' }}>
              <Typography variant="caption" style={{}}>
                <FormattedMessage id="hello" />,
              </Typography>
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                {userData.name}
              </Typography>
            </Col>
            <Typography style={{ marginLeft: '8px' }}>
              <span
                style={{
                  display: 'inline-block',
                  marginLeft: '6px',
                  fontSize: '10px',
                  transition: 'all 300ms',
                  transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                  cursor: 'pointer',
                }}
              >
                &#9650;
              </span>
            </Typography>
          </ButtonBase>
          <Col
            style={{
              position: 'absolute',
              width: ref ? ref.current?.offsetWidth : 150,
              color: 'black',
              zIndex: 110,
              top: HEADER_HEIGHT,
              minWidth: 212,
              right: '0px',
            }}
          >
            <Collapse in={open}>
              <div
                style={{
                  background: 'white',
                  boxShadow: '0px 32px 32px rgba(0, 0, 0, 0.2)',
                  border: `1px solid ${GREY}`,
                  justifyContent: 'space-between',
                  cursor: 'auto',
                  overflow: 'hidden',
                  borderRadius: '0px 0px 10px 10px',
                }}
              >
                <Link
                  to={{
                    pathname: ROUTES.managerAccount.accountInfo,
                    state: { ..._.get(router, 'location.state', {}), [`${router.location.pathname}`]: true },
                  }}
                >
                  <Button
                    disableElevation
                    fullWidth
                    style={{ justifyContent: 'flex-start', minHeight: 60, padding: '16px 24px' }}
                  >
                    <IconPerson style={{ marginRight: 16 }} />
                    <Typography variant="caption" color="secondary">
                      <FormattedMessage id="accountInfo" />
                    </Typography>
                  </Button>
                </Link>

                <Divider style={{ margin: '0px 24px' }} />
                <Button
                  disableElevation
                  fullWidth
                  style={{ justifyContent: 'flex-start', minHeight: 60, padding: '16px 24px' }}
                  onClick={() => dispatch(logout())}
                >
                  <IconLogout style={{ marginRight: 16 }} />
                  <Typography variant="caption">
                    <FormattedMessage id="logout" />
                  </Typography>
                </Button>
              </div>
            </Collapse>
          </Col>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps)(UserInfoDropdown);
