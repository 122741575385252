import { push } from 'connected-react-router';
import { get, remove, set } from 'js-cookie';
import { batch } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { API_PATHS } from '../../../configs/API';
import { some, SUCCESS_CODE } from '../../../constants';
import { AppState, clearStoreAfterLogout } from '../../../redux/reducers';
// import { loadParamsToStore } from '../../../utils';
import { setUserData } from '../../account/redux/accountReducer';
import { fetchThunk } from '../../common/redux/thunk';
import { ACCESS_TOKEN } from '../constants';
import {
  inAction,
  out,
  setAuthenticating,
  setLoginErrorMsg,
  setValidatingToken,
} from './authReducer';
import { ParamsForgotPassword, ParamsLogin } from '../utils';

export function authIn(
  userData: some,
  skipSaga: boolean = false,
): ThunkAction<void, AppState, null, Action<string>> {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setUserData(userData));
    if (!state.auth.auth) {
      dispatch(inAction(skipSaga));
    }
  };
}

export function validateAccessToken(
  periodic = false,
): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    let prevAccessToken = get(ACCESS_TOKEN);
    let first = true;
    const fn = async (force = false) => {
      const accessToken = get(ACCESS_TOKEN);
      const state = getState();
      if (accessToken) {
        if (first || prevAccessToken !== accessToken || force) {
          first = false;
          dispatch(setValidatingToken(true));
          try {
            const json = await dispatch(fetchThunk(`${API_PATHS.getUserInfo}`, 'get'));
            if (json && json?.code === SUCCESS_CODE) {
              dispatch(authIn(json.data));
              prevAccessToken = accessToken;
            } else if (getState().auth.auth) {
              dispatch(out());
              remove(ACCESS_TOKEN);
              dispatch(setUserData());
              dispatch(
                push({
                  pathname: '/',
                }),
              );
            }
          } finally {
            dispatch(setValidatingToken(false));
          }
        }
      } else if (state.auth.auth) {
        dispatch(out());
      }
    };

    if (periodic) {
      setInterval(fn, 1000);
    } else {
      fn(true);
    }
  };
}

export function login(
  paramLogin: ParamsLogin,
): ThunkAction<Promise<void>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    dispatch(setAuthenticating(true));
    const json = await dispatch(
      fetchThunk(
        API_PATHS.login,
        'post',
        JSON.stringify({ password: paramLogin.password, email: paramLogin.username }),
      ),
    );
    if (json?.code === SUCCESS_CODE) {
      set(ACCESS_TOKEN, json.data.accessToken);
      dispatch(authIn(json.data));
    }
    dispatch(setLoginErrorMsg(json?.message));
    dispatch(setAuthenticating(false));
  };
}

export function logout(): ThunkAction<void, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    await dispatch(fetchThunk(API_PATHS.logout, 'post'));
    remove(ACCESS_TOKEN);
    batch(() => {
      dispatch(out());
      dispatch(clearStoreAfterLogout());
    });
    // loadParamsToStore(dispatch, new URLSearchParams(getState().router.location.search));
  };
}
export function forgotPassWord(email: string): ThunkAction<some, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    return await dispatch(fetchThunk(API_PATHS.forgetPass, 'post', JSON.stringify({ email })));
  };
}

export function verifyForgotPassWord(
  params: ParamsForgotPassword,
): ThunkAction<some, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    return await dispatch(fetchThunk(API_PATHS.verifyForgetPass, 'post', JSON.stringify(params)));
  };
}
