import { goBack } from 'connected-react-router';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'typesafe-actions';
import { ROUTES } from '../../../configs/routes';
import { some, SUCCESS_CODE } from '../../../constants';
import { AppState } from '../../../redux/reducers';
import { snackbarSetting } from '../../common/components/elements';
import UpdateAccountDesktop from '../component/UpdateAccountDesktop';
import { onUpdateAccount, setUserData } from '../redux/accountReducer';

const mapStateToProps = (state: AppState) => {
  return {
    userData: state.account.userData,
    router: state.router,
  };
};

interface Props extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
  onUpdate(info: some): void;
}

const UpdateAccount: React.FunctionComponent<Props> = props => {
  const { dispatch, userData } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const intl = useIntl();

  const goBackToList = React.useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const onUpdate = React.useCallback(
    async (info: some) => {
      const json = await dispatch(onUpdateAccount(info));
      if (json?.code === SUCCESS_CODE) {
        dispatch(setUserData(json.data));
        goBackToList();
        enqueueSnackbar(
          'auth.updateSuccess',
          snackbarSetting(
            key => closeSnackbar(key),
            intl.formatMessage({ id: 'auth.updateSuccess' }),
          ),
        );
      } else {
        json?.message &&
          enqueueSnackbar(
            json?.message,
            snackbarSetting(key => closeSnackbar(key), json?.message),
          );
      }
    },
    [closeSnackbar, dispatch, enqueueSnackbar, goBackToList, intl],
  );

  if (!userData) {
    return <Redirect to={ROUTES.managerAccount.accountInfo} />;
  }
  return <UpdateAccountDesktop data={userData} onUpdate={onUpdate} onClose={goBackToList} />;
};

export default connect(mapStateToProps)(UpdateAccount);
