import { combineReducers } from 'redux';

import managerUserReducer, { ManagerUserState, DEFAULT_MANAGER_USER_STATE } from '../managerUser/redux/managerUserReducer';
import managerPermissionReducer, {
  ManagerPermissionState, DEFAULT_MANAGER_PERMISSION_STATE,
} from '../managerPermission/redux/managerPermissionReducer';

export const DEFAULT_MANAGER_USER_PERMISSION_STATE: ManagerUserPermissionState = {
  managerUserState: DEFAULT_MANAGER_USER_STATE,
  managerPermissionState: DEFAULT_MANAGER_PERMISSION_STATE,
};
export interface ManagerUserPermissionState {
  managerUserState: ManagerUserState;
  managerPermissionState: ManagerPermissionState;
}

export default combineReducers({
  managerUserState: managerUserReducer,
  managerPermissionState: managerPermissionReducer,
});
