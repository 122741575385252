import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FOOTER_GREY, WHITE } from '../../configs/colors';
import { Row } from '../../modules/common/components/elements';
import { ReactComponent as IconFacebook } from '../../svg/facebook.svg';
import { ReactComponent as IconGoogle } from '../../svg/google_plus.svg';

interface Props {}

const DefaultFooter: React.FC<Props> = () => {
  return (
    <Row
      style={{
        backgroundColor: FOOTER_GREY,
        padding: '8px 32px',
      }}
    >
      <Typography variant="caption" style={{ color: WHITE, opacity: 1 }}>
        <FormattedMessage id="footer.license" />
      </Typography>

      <Divider
        orientation="vertical"
        style={{ minHeight: '16px', margin: '12px', backgroundColor: 'white' }}
      />

      <IconGoogle />
      <IconFacebook />
    </Row>
  );
};

export default DefaultFooter;
