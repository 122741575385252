import {
  IPermissionGroup,
  IFeature,
  IPermission,
  IFeaturePermission,
  IPermissionValidation,
  IUserDetail,
  IUserDetailValidation,
} from '../../common/models/userPermission';

import { validEmailRegex } from '../../../constants';

export interface PaginationParams {
  pageOffset: number;
  pageSize: number;
}

export interface PermissionFilterParams extends PaginationParams {
  roleCode?: string;
  roleName?: string;
  isSell?: boolean;
  listFeature?: string;
}

export interface UserFilterParams extends PaginationParams {
  email?: string;
  name?: string;
  phoneNumber?: string;
  roleGroupId?: number;
  areaId?: number;
  placeId?: number;
  status?: number;
  pageOffset: number;
  pageSize: number;
}

export const defaultPermissionGroup: IPermissionGroup = {
  roleCode: '',
  roleName: '',
  isSell: false,
  listFeature: [],
};

export const defaultFeature: IFeature = {
  name: '',
  module: '',
};

export const defaultPermission: IPermission = {
  readPer: false,
  addPer: false,
  editPer: false,
  deletePer: false,
  exportPer: false,
};
export const defaultActivePermission: IPermission = {
  readPer: true,
  addPer: true,
  editPer: true,
  deletePer: true,
  exportPer: true,
};

export const defaultFeaturePermission: IFeaturePermission = {
  ...defaultFeature,
  ...defaultPermission,
};

export const defaultPermissionValidation: IPermissionValidation = {
  roleCode: '',
  roleName: '',
  listFeature: '',
  featureValid: '',
};

export function validationPermissionGroup(data: IPermissionGroup): IPermissionValidation {
  return {
    roleCode: data?.roleCode.trim() ? '' : 'managerUserPermission.roleCodeRequired',
    roleName: data?.roleName.trim() ? '' : 'managerUserPermission.roleNameRequired',
    listFeature: data?.listFeature?.length > 0 ? '' : 'managerUserPermission.listFeatureRequired',
    featureValid:
      data?.listFeature?.filter(
        v => v.addPer || v.deletePer || v.editPer || v.exportPer || v.readPer,
      )?.length > 0
        ? ''
        : 'managerUserPermission.listFeatureValid',
  };
}

export function validPermissionGroup(data: IPermissionValidation) {
  return data.roleCode || data.roleName || data.listFeature || data.featureValid;
}

export const defaultPagination: PaginationParams = {
  pageOffset: 0,
  pageSize: 10,
};

export const defaultPermissionFilter: PermissionFilterParams = {
  ...defaultPagination,
};

// USER
export const defaultUserDetail: IUserDetail = {
  name: '',
  email: '',
  phoneNumber: '',
  status: 1,
};

export const defaultUserDetailValidation: IUserDetailValidation = {
  name: '',
  email: '',
  phoneNumber: '',
  roleGroupId: '',
  areaId: '',
  placeId: '',
};

export function validationUserDetail(data: IUserDetail): IUserDetailValidation {
  const emailInput = data?.email.trim();
  let email: string = 'managerUserPermission.error.email';

  if (emailInput) {
    if (validEmailRegex.test(emailInput)) {
      email = '';
    } else {
      email = 'managerUserPermission.error.emailInvalid';
    }
  }

  return {
    name: data?.name.trim() ? '' : 'managerUserPermission.error.name',
    email,
    phoneNumber: data?.phoneNumber.trim() ? '' : 'managerUserPermission.error.phoneNumber',
    roleGroupId: data?.roleGroupId ? '' : 'managerUserPermission.error.roleGroupId',
    areaId: data?.areaId ? '' : 'managerUserPermission.error.areaId',
    placeId: data?.placeId ? '' : 'managerUserPermission.error.placeId',
  };
}

export function validUserDetail(data: IUserDetailValidation) {
  return (
    data.email || data.name || data.phoneNumber || data.roleGroupId || data.areaId || data.placeId
  );
}

export const defaultUserFilter: UserFilterParams = {
  ...defaultPagination,
  roleGroupId: -1,
  areaId: -1,
  placeId: -1,
  status: -1,
};
