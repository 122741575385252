export type some = { [key: string]: any };

export const SKIP_RESET_FILTER = 'skipResetFilter';

export const SUCCESS_CODE = 200;

export const CHECK_NETWORK_URL = process.env.CHECK_NETWORK_URL ? process.env.CHECK_NETWORK_URL : '';

export const BASE_URL = process.env.BASE_URL ? process.env.BASE_URL : '';

export const DEV = process.env.REACT_APP_IS_DEV



export type ServiceType =
  | 'managerDevice'
  | 'configurationDevice'
  | 'paymentMethod'
  | 'managerBanner'
  | 'managerBannerSelfOrder'
  | 'managerUserPermission'
  | 'managerTransaction'
  | 'managerAccount';

export type PermissionType = 'readPer' | 'addPer' | 'editPer' | 'deletePer' | 'exportPer';

export interface Role {
  module: ServiceType;
  role: PermissionType;
}
export interface RoutesTabType {
  name: ServiceType | string; // key of module
  title?: string; // string id of title breadcrub sidebar helmet
  path?: string; // path link
  component?: any;
  isModule?: boolean; // is highest page
  hidden?: boolean;
  hiddenMenu?: RoutesTabType[];
  subMenu?: RoutesTabType[];
  backStep?: number;
  exact?: boolean;
  listRole?: Role[];
}

export const GAMI = process.env.REACT_APP_PRODUCT === 'gami';

export const PAGE_SIZE = 10;

export const ROW_PER_PAGE = [10, 20, 30, 50];

export const validEmailRegex = /^[\w-+]+(\.[\w]+)*@[\w-]+(\.[\w]+)*(\.[a-z]{2,})$/;
export const validNameRegex = /^[a-zA-Z ]+$/;
export const validUserPassRegex = /^[a-zA-Z0-9!@#$%^&*]+$/;
export const validTelephoneRegex = /^[0-9]+$/;
export const validNumberRegex = /^[0-9]+$/;
export const noBlankSpaceRegex = /^\S+$/;
export const validVietnamTelephoneRegex = /^[0-9]{8,11}$/;
export const specialCharacterRegex = /[\]\\[!@#$%^&*(),.?":{}|<>+_;=~`'\\/-]/;
export const invalidUserNameRegex = /[\]\\[!#$%^&*(),?":{}|<>+;=~`'\\/\s-]/;
