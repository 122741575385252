import { Collapse, Tooltip, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PRIMARY, WHITE, RED } from '../../configs/colors';
import { some, RoutesTabType } from '../../constants';
import { Row } from '../../modules/common/components/elements';
import { RawLink } from '../../modules/common/components/Link';
import { AppState } from '../../redux/reducers';
import { ASIDE_MIN_WIDTH } from '../constants';
import { ButtonRow } from './DefaultAside';
import DefaultAsideItemsIcon from './DefaultAsideItemsIcon';
import { getListRoutesActivate } from '../utils';
import { ROUTES } from '../../configs/routes';
import _ from 'lodash';

const mapStateToProps = (state: AppState) => {
  return {
    router: state.router,
    managerStatus: state.smartPosSo.configurationDevice.managerStatus,
  };
};

interface Props extends ReturnType<typeof mapStateToProps> {
  data: RoutesTabType;
  userData: some;
  pathname: string;
  open: boolean;
  listRouterActive: some[];
}

const DefaultAsideItems: React.FC<Props> = (props: Props) => {
  const { data, pathname, open, router, listRouterActive, userData, managerStatus } = props;
  const outOfPaperCount = React.useMemo(() => {
    return managerStatus?.data?.data.filter((item: some) => item.statusPaper.id === 3).length;
  }, [managerStatus]);

  const [openList, setOpen] = React.useState(false);

  const listRoutes = React.useMemo(() => {
    return data?.subMenu
      ? getListRoutesActivate(userData?.roleGroup?.listFeature, data?.subMenu)
      : [];
  }, [data, userData]);

  const checkPermission = React.useMemo(() => {
    const listRole = userData?.roleGroup?.listFeature;
    for (let i = 0; i < listRole?.length; i += 1) {
      if (data.name.includes(listRole[i].module)) {
        return true;
      }
    }
    return false;
  }, [data.name, userData]);

  const checkIsActive = React.useMemo(() => {
    let openTemp = false;
    listRouterActive?.forEach((item: some) => {
      if (item.name === data.name) {
        openTemp = true;
      }
    });
    return openTemp;
  }, [data.name, listRouterActive]);

  const getOpenMenu = React.useMemo(() => {
    let tempOpen = true;
    if (!open) {
      tempOpen = false;
    }
    if (openList) {
      tempOpen = false;
    }
    return tempOpen;
  }, [open, openList]);

  const getOpenItem = React.useMemo(() => {
    let tempOpen = true;
    if (!open) {
      tempOpen = false;
    }
    if (checkIsActive) {
      tempOpen = false;
    }
    return tempOpen;
  }, [checkIsActive, open]);

  React.useEffect(() => {
    if (data.name === 'configurationDevice' && outOfPaperCount > 0) {
      setOpen(true);
    } else {
      setOpen(checkIsActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outOfPaperCount]);

  if (data.hidden || !checkPermission) {
    return null;
  }
  return (
    <>
      <div style={{ transition: 'all 0.2' }}>
        {data.subMenu ? (
          <>
            <Tooltip
              title={
                data.name || data.title ? <FormattedMessage id={data.name || data.title} /> : ''
              }
              placement="right"
            >
              <ButtonRow
                style={{
                  backgroundColor: openList ? 'white' : undefined,
                }}
                onClick={() => setOpen(!openList)}
              >
                <Row style={{ width: ASIDE_MIN_WIDTH, justifyContent: 'center' }}>
                  <DefaultAsideItemsIcon open={getOpenMenu} name={data.name} />
                </Row>
                <Typography
                  variant="body2"
                  style={{
                    color: openList ? 'black' : 'white',
                    flex: 1,
                    fontWeight: 500,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: 150,
                  }}
                >
                  {data.name && <FormattedMessage id={data.name} />}
                </Typography>
                <ArrowDropDownIcon
                  style={{
                    color: openList ? 'black' : 'white',
                    transition: 'all 300ms',
                    transform: openList ? 'rotate(180deg)' : 'rotate(0deg)',
                    cursor: 'pointer',
                  }}
                />
              </ButtonRow>
            </Tooltip>
            <Collapse in={openList && open}>
              {listRoutes.map((v: RoutesTabType, index: number) => (
                <DefaultAsideItems
                  userData={userData}
                  managerStatus={managerStatus}
                  key={index}
                  open={open}
                  listRouterActive={listRouterActive}
                  data={v}
                  pathname={pathname}
                  router={router}
                />
              ))}
            </Collapse>
          </>
        ) : (
          <Tooltip
            title={data.name || data.title ? <FormattedMessage id={data.name || data.title} /> : ''}
            placement="right"
          >
            <RawLink
              to={{
                pathname: data.path,
                state: {
                  ..._.get(router, 'location.state', {}),
                  [`${data.path}`]: true,
                },
              }}
              style={{ display: 'flex', flex: 1 }}
            >
              <ButtonRow
                style={{
                  backgroundColor: checkIsActive ? PRIMARY : undefined,
                }}
              >
                <Row style={{ width: ASIDE_MIN_WIDTH, justifyContent: 'center' }}>
                  <DefaultAsideItemsIcon open={getOpenItem} name={data.name} />
                  {data.path === ROUTES.configurationDevice.managerStatus.statusDevice &&
                    outOfPaperCount > 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: RED,
                        color: WHITE,
                        padding: 6,
                        borderRadius: '50%',
                        width: 8,
                        height: 8,
                      }}
                    >
                      {outOfPaperCount}
                    </div>
                  ) : (
                    undefined
                  )}
                </Row>
                <Typography
                  variant="body2"
                  style={{
                    color: 'white',
                    flex: 1,
                    fontWeight: data.isModule ? 500 : undefined,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: 150,
                  }}
                >
                  {data.name && <FormattedMessage id={data.name} />}
                </Typography>
              </ButtonRow>
            </RawLink>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps)(DefaultAsideItems);
