import { validEmailRegex } from '../../constants';

export type Phase = 'login' | 'forgotPassword' | 'setupPassword';

export interface ParamsLogin {
  username: string;
  password: string;
}
export const defaultParamsLogin: ParamsLogin = {
  username: '',
  password: '',
};
export interface ParamsLoginValidation {
  username: boolean;
  usernameValid: boolean;
  password: boolean;
  passwordValid: boolean;
}
export const defaultParamsLoginValidation: ParamsLoginValidation = {
  username: true,
  usernameValid: true,
  password: true,
  passwordValid: true,
};
export function validationLogin(params: ParamsLogin): ParamsLoginValidation {
  const username = params.username ? !!params.username.trim() : false;
  const usernameValid = params.username ? validEmailRegex.test(params.username) : false;
  const password = params.password ? !!params.password.trim() : false;
  const passwordValid = params.password ? params.password.length >= 6 : false;
  return { username, usernameValid, password, passwordValid };
}
export function validLogin(valid: ParamsLoginValidation) {
  if (!valid.username) {
    return 'auth.error.username';
  }
  if (!valid.usernameValid) {
    return 'auth.error.usernameValid';
  }
  if (!valid.password) {
    return 'auth.error.password';
  }
  if (!valid.passwordValid) {
    return 'auth.error.passwordValid';
  }
  return '';
}

export interface ParamsForgotPassword {
  email?: string;
  otp: string;
  newPass: string;
  verifyPass: string;
}
export const defaultParamsForgotPassword: ParamsForgotPassword = {
  otp: '',
  newPass: '',
  verifyPass: '',
};
export interface ValidationParamsForgotPassword {
  otp: boolean;
  newPass: boolean;
  verifyPass: boolean;
  samePassword: boolean;
  passValid: boolean;
}
export const defaultParamsForgotPasswordValidation: ValidationParamsForgotPassword = {
  otp: true,
  newPass: true,
  verifyPass: true,
  samePassword: true,
  passValid: true,
};

export function validationParamsForgotPassword(
  params: ParamsForgotPassword,
): ValidationParamsForgotPassword {
  const otp = params.otp ? !!params?.otp?.trim() : false;
  const newPass = params.newPass ? !!params?.newPass?.trim() : false;
  const verifyPass = params.verifyPass ? !!params?.verifyPass?.trim() : false;
  const samePassword = params.newPass === params.verifyPass;
  const passValid = params?.newPass?.length >= 6 && params?.verifyPass?.length >= 6;
  return { otp, newPass, verifyPass, samePassword, passValid };
}

export function validParamsForgotPassword(validation: ValidationParamsForgotPassword) {
  if (!validation.otp) {
    return 'auth.error.otp';
  }
  if (!validation.newPass) {
    return 'auth.error.newPassword';
  }
  if (!validation.verifyPass) {
    return 'auth.error.verifyPassword';
  }
  if (!validation.samePassword) {
    return 'auth.error.samePassword';
  }
  if (!validation.passValid) {
    return 'auth.error.passwordValid';
  }
  return '';
}
