import Loadable from 'react-loadable';
import { GAMI, ServiceType, RoutesTabType } from "../constants";

import AccountInfo from '../modules/account/pages/AccountInfo';
import UpdateAccount from '../modules/account/pages/UpdateAccount';
import RouteLoading from '../modules/common/components/RouteLoading';

/* -------ManagerBanner--------*/
const BannerResult = Loadable({
  loader: () => import('../modules/smart-pos-so/managerBanner/bannerGroup/pages/Result'),
  loading: RouteLoading,
  timeout: 500,
});

const UpdateAccountInfo = Loadable({
  loader: () => import('../modules/smart-pos-so/managerBanner/bannerGroup/pages/UpdateOrCreate'),
  loading: RouteLoading,
  timeout: 500,
});

const BannerGroupSOResult = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/managerBanner/bannerGroupSO/pages/BannerGroupSOResult'),
  loading: RouteLoading,
  timeout: 500,
});

const UpdateBannerGroupSO = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/managerBanner/bannerGroupSO/pages/UpdateBannerGroupSO'),
  loading: RouteLoading,
  timeout: 500,
});

/* -------ManagerTransaction--------*/
const TransactionResult = Loadable({
  loader: () => import('../modules/smart-pos-so/managerTransaction/pages/TransactionResult'),
  loading: RouteLoading,
  timeout: 500,
});

const TransactionDetail = Loadable({
  loader: () => import('../modules/smart-pos-so/managerTransaction/pages/TransactionDetail'),
  loading: RouteLoading,
  timeout: 500,
});

const CreateUpdateDevice = Loadable({
  loader: () => import('../modules/smart-pos-so/manageDevice/pages/CreateUpdateDevice'),
  loading: RouteLoading,
  timeout: 500,
});

const DetailDevice = Loadable({
  loader: () => import('../modules/smart-pos-so/manageDevice/pages/DetailDevice'),
  loading: RouteLoading,
  timeout: 500,
});

/* -------ManagerDevice--------*/
const ManagerDevice = Loadable({
  loader: () => import('../modules/smart-pos-so/manageDevice/pages/ManagerDevice'),
  loading: RouteLoading,
  timeout: 500,
});

const ManagerParameter = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/configurationDevice/managerParameter/pages/ManagerParameter'),
  loading: RouteLoading,
  timeout: 500,
});

const UpdateParameter = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/configurationDevice/managerParameter/pages/UpdateParameter'),
  loading: RouteLoading,
  timeout: 500,
});

const ManagerStatus = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/configurationDevice/managerStatus/pages/ManagerStatus'),
  loading: RouteLoading,
  timeout: 500,
});

const ManagerError = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/configurationDevice/managerError/pages/ManagerError'),
  loading: RouteLoading,
  timeout: 500,
});

/* -------ManagerUserPermission--------*/
const PermissionResult = Loadable({
  loader: () =>
    import(
      '../modules/smart-pos-so/managerUserPermission/managerPermission/pages/PermissionResult'
    ),
  loading: RouteLoading,
  timeout: 500,
});

const UpdateOrCreatePermission = Loadable({
  loader: () =>
    import(
      '../modules/smart-pos-so/managerUserPermission/managerPermission/pages/UpdateOrCreatePermission'
    ),
  loading: RouteLoading,
  timeout: 500,
});

const UserResult = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/managerUserPermission/managerUser/pages/UserResult'),
  loading: RouteLoading,
  timeout: 500,
});

const UpdateOrCreateUser = Loadable({
  loader: () =>
    import('../modules/smart-pos-so/managerUserPermission/managerUser/pages/UpdateOrCreateUser'),
  loading: RouteLoading,
  timeout: 500,
});
/* -------ManagerPaymenetMethod--------*/
const ManagePaymentMethod = Loadable({
  loader: () => import('../modules/smart-pos-so/managePaymentMethod/pages/ManagePaymentMethod'),
  loading: RouteLoading,
  timeout: 500,
});
const UpdatePaymentMethod = Loadable({
  loader: () => import('../modules/smart-pos-so/managePaymentMethod/pages/UpdatePaymentMethod'),
  loading: RouteLoading,
  timeout: 500,
});

function buildRoutePath(moduleName: ServiceType, path: string) {
  return `/${moduleName}${path}`;
}

export const ROUTES = {
  login: '/login',
  signUp: '/signUp',

  managerDevice: {
    managerDevice: buildRoutePath('managerDevice', ''),
    createDevice: buildRoutePath('managerDevice', '/createDevice'),
    updateDevice: buildRoutePath('managerDevice', '/updateDevice'),
    detailDevice: buildRoutePath('managerDevice', '/detailDevice'),
  },
  configurationDevice: {
    managerParameter: {
      parameterDevice: buildRoutePath('configurationDevice', '/parameterDevice'),
      updateParamsDevice: buildRoutePath(
        'configurationDevice',
        '/managerParameter/updateParamsDevice',
      ),
    },
    managerStatus: {
      statusDevice: buildRoutePath('configurationDevice', '/statusDevice'),
    },
    managerError: {
      errorDevice: buildRoutePath('configurationDevice', '/errorDevice'),
    },
  },
  paymentMethod: {
    listPaymentMethod: buildRoutePath('paymentMethod', ''),
    updatePaymentMethod: buildRoutePath('paymentMethod', '/updatePaymentMethod'),
  },
  manageBanner: {
    bannerGroup: {
      result: buildRoutePath('managerBanner', '/bannerResult'),
      create: buildRoutePath('managerBanner', '/createBanner'),
      update: buildRoutePath('managerBanner', '/updateBanner'),
    },
    bannerSelfOrder: {
      result: buildRoutePath('managerBanner', '/bannerSelfOrderResult'),
      update: buildRoutePath('managerBanner', '/updateBannerSelfOrder'),
    },
  },
  managerUserPermission: {
    permission: {
      result: buildRoutePath('managerUserPermission', '/userPermissionResult'),
      create: buildRoutePath('managerUserPermission', '/createPermission'),
      update: buildRoutePath('managerUserPermission', '/updatePermission'),
    },
    user: {
      result: buildRoutePath('managerUserPermission', '/userResult'),
      create: buildRoutePath('managerUserPermission', '/createUser'),
      update: buildRoutePath('managerUserPermission', '/updateUser'),
    },
  },
  managerAccount: {
    accountInfo: buildRoutePath('managerAccount', '/accountInfo'),
    updateAccountInfo: buildRoutePath('managerAccount', '/updateAccountInfo'),
  },
  managerTransaction: {
    result: buildRoutePath('managerTransaction', ''),
    detail: buildRoutePath('managerTransaction', '/transactionDetail'),
  },
};

export const ROUTES_TAB: RoutesTabType[] = [
  {
    name: 'managerDevice',
    path: ROUTES.managerDevice.managerDevice,
    component: ManagerDevice,
    isModule: true,
    listRole: [
      {
        module: 'managerDevice',
        role: 'readPer',
      },
    ],
    hiddenMenu: [
      {
        name: 'managerDevice.createDevice',
        path: ROUTES.managerDevice.createDevice,
        listRole: [
          {
            module: 'managerDevice',
            role: 'addPer',
          },
        ],
        component: CreateUpdateDevice,
        hidden: true,
      },
      {
        name: 'managerDevice.updateDevice',
        path: ROUTES.managerDevice.updateDevice,
        listRole: [
          {
            module: 'managerDevice',
            role: 'editPer',
          },
        ],
        component: CreateUpdateDevice,
        hidden: true,
      },
      {
        name: 'managerDevice.detailDevice',
        path: ROUTES.managerDevice.detailDevice,
        listRole: [
          {
            module: 'managerDevice',
            role: 'readPer',
          },
        ],
        component: DetailDevice,
        hidden: true,
      },
    ],
  },
  {
    name: 'managerBanner',
    isModule: true,
    hidden: !!GAMI,
    subMenu: [
      {
        name: 'managerBanner.bannerGroups',
        path: ROUTES.manageBanner.bannerGroup.result,
        listRole: [
          {
            module: 'managerBanner',
            role: 'readPer',
          },
        ],
        component: BannerResult,
        hiddenMenu: [
          {
            name: 'managerBanner.createBannerGroup',
            path: ROUTES.manageBanner.bannerGroup.create,
            listRole: [
              {
                module: 'managerBanner',
                role: 'addPer',
              },
            ],
            component: UpdateAccountInfo,
            hidden: true,
          },
          {
            name: 'managerBanner.updateBannerGroup',
            path: ROUTES.manageBanner.bannerGroup.update,
            listRole: [
              {
                module: 'managerBanner',
                role: 'editPer',
              },
            ],
            component: UpdateAccountInfo,
            hidden: true,
          },
        ],
      },
      {
        name: 'managerBannerSO',
        path: ROUTES.manageBanner.bannerSelfOrder.result,
        listRole: [
          {
            module: 'managerBanner',
            role: 'readPer',
          },
          {
            module: 'managerDevice',
            role: 'readPer',
          },
        ],
        component: BannerGroupSOResult,
        hiddenMenu: [
          {
            name: 'managerBannerSO.updateBannerSO',
            path: ROUTES.manageBanner.bannerSelfOrder.update,
            listRole: [
              {
                module: 'managerDevice',
                role: 'editPer',
              },
            ],
            component: UpdateBannerGroupSO,
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    name: 'configurationDevice',
    isModule: true,
    subMenu: [
      {
        name: 'configurationDevice.managerParams',
        path: ROUTES.configurationDevice.managerParameter.parameterDevice,
        listRole: [
          {
            module: 'configurationDevice',
            role: 'readPer',
          },
        ],
        component: ManagerParameter,
        hiddenMenu: [
          {
            name: 'configurationDevice.updateParameter',
            path: ROUTES.configurationDevice.managerParameter.updateParamsDevice,
            listRole: [
              {
                module: 'configurationDevice',
                role: 'editPer',
              },
            ],
            component: UpdateParameter,
          },
        ],
      },
      {
        name: 'configurationDevice.statusDevice',
        path: ROUTES.configurationDevice.managerStatus.statusDevice,
        listRole: [
          {
            module: 'configurationDevice',
            role: 'readPer',
          },
          {
            module: 'managerDevice',
            role: 'readPer',
          },
        ],

        component: ManagerStatus,
      },
      {
        name: 'configurationDevice.errorDevice',
        path: ROUTES.configurationDevice.managerError.errorDevice,
        listRole: [
          {
            module: 'configurationDevice',
            role: 'readPer',
          },
        ],
        component: ManagerError,
      },
    ],
  },
  {
    name: 'managerUserPermission',
    isModule: true,
    subMenu: [
      {
        name: 'managerUserPermission.userList',
        path: ROUTES.managerUserPermission.user.result,
        listRole: [
          {
            module: 'managerUserPermission',
            role: 'readPer',
          },
        ],
        component: UserResult,
        hiddenMenu: [
          {
            name: 'managerUserPermission.createAccount',
            path: ROUTES.managerUserPermission.user.create,
            listRole: [
              {
                module: 'managerUserPermission',
                role: 'addPer',
              },
            ],
            component: UpdateOrCreateUser,
            hidden: true,
          },
          {
            name: 'managerUserPermission.updateAccount',
            path: ROUTES.managerUserPermission.user.update,
            listRole: [
              {
                module: 'managerUserPermission',
                role: 'editPer',
              },
            ],
            component: UpdateOrCreateUser,
            hidden: true,
          },
        ],
      },
      {
        name: 'managerUserPermission.permissionGroup',
        path: ROUTES.managerUserPermission.permission.result,
        listRole: [
          {
            module: 'managerUserPermission',
            role: 'readPer',
          },
        ],
        component: PermissionResult,
        hiddenMenu: [
          {
            name: 'managerUserPermission.create',
            path: ROUTES.managerUserPermission.permission.create,
            listRole: [
              {
                module: 'managerUserPermission',
                role: 'addPer',
              },
            ],
            component: UpdateOrCreatePermission,
            hidden: true,
          },
          {
            name: 'managerUserPermission.update',
            path: ROUTES.managerUserPermission.permission.update,
            listRole: [
              {
                module: 'managerUserPermission',
                role: 'editPer',
              },
            ],
            component: UpdateOrCreatePermission,
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    name: 'managerTransaction',
    path: ROUTES.managerTransaction.result,
    listRole: [
      {
        module: 'managerTransaction',
        role: 'readPer',
      },
    ],
    component: TransactionResult,
    isModule: true,
    hiddenMenu: [
      {
        name: 'managerTransaction.transactionDetail',
        path: ROUTES.managerTransaction.detail,
        listRole: [
          {
            module: 'managerTransaction',
            role: 'readPer',
          },
        ],
        component: TransactionDetail,
        hidden: true,
      },
    ],
  },
  {
    name: 'paymentMethod',
    path: ROUTES.paymentMethod.listPaymentMethod,
    listRole: [
      {
        module: 'paymentMethod',
        role: 'readPer',
      },
    ],
    component: ManagePaymentMethod,
    isModule: true,
    hiddenMenu: [
      {
        name: 'paymentMethod.updatePaymentMethod',
        path: ROUTES.paymentMethod.updatePaymentMethod,
        listRole: [
          {
            module: 'paymentMethod',
            role: 'editPer',
          },
        ],
        component: UpdatePaymentMethod,
        hidden: true,
      },
    ],
  },
  {
    name: 'managerAccount',
    path: ROUTES.managerAccount.accountInfo,
    component: AccountInfo,
    hidden: true,
    hiddenMenu: [
      {
        name: 'managerAccount.updateAccountInfo',
        path: ROUTES.managerAccount.updateAccountInfo,
        component: UpdateAccount,
        hidden: true,
      },
    ],
  },
];
