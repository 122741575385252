import { Typography } from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { some } from '../../../constants';
import { BootstrapInput } from '../../common/components/elements';
import { RED, GREY } from '../../../configs/colors';

export const FieldDiv = styled.div`
  margin-right: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const redMark = (
  <>
    (<span style={{ color: RED }}>*</span>)
  </>
);

export const FreeTextField: React.SFC<{
  text?: string;
  placeholder?: string;
  header?: React.ReactNode;
  update: (text: string) => void;
  regex?: RegExp;
  optional?: boolean;
  valid?: boolean;
  type?: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  endAdornment?: React.ReactNode;
  onClick?: MouseEventHandler<some>;
  inputProps?: InputBaseComponentProps;
  disabled?: boolean;
  inputComponent?: any;
  autoComplete?: string;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: string | number;
}> = props => {
  const {
    text,
    placeholder,
    header,
    update,
    regex,
    optional,
    valid,
    style,
    endAdornment,
    inputStyle,
    headerStyle,
    type,
    onClick,
    inputProps,
    disabled,
    inputComponent,
    autoComplete,
    autoFocus,
    multiline,
    rows,
  } = props;

  return (
    <FieldDiv style={style}>
      {header && (
        <div
          style={{
            marginLeft: '12px',
            marginBottom: '4px',
            color: disabled ? GREY : undefined,
            ...headerStyle,
          }}
        >
          <Typography variant="body2">
            {header}
            &nbsp;
            {!optional && redMark}
          </Typography>
        </div>
      )}
      <BootstrapInput
        disabled={disabled}
        style={inputStyle}
        fullWidth
        value={text}
        error={!valid}
        placeholder={placeholder}
        onChange={e => {
          if (!regex || regex.test(e.target.value) || !e.target.value) {
            update(e.target.value);
          }
        }}
        type={type || 'text'}
        endAdornment={endAdornment}
        inputProps={{
          style: {
            outline: 'none',
            border: 'none',
            position: 'relative',
          },
          ...inputProps,
        }}
        autoComplete={autoComplete}
        onClick={onClick}
        inputComponent={inputComponent}
        autoFocus={autoFocus}
        multiline={multiline}
        rows={rows}
      />
    </FieldDiv>
  );
};

FreeTextField.defaultProps = {
  valid: true,
};
