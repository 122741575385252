import { Button, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'typesafe-actions';
import voca from 'voca';
import { GREY } from '../../../../configs/colors';
import { SUCCESS_CODE, noBlankSpaceRegex } from '../../../../constants';
import { AppState } from '../../../../redux/reducers';
import { ReactComponent as IconEyeClose } from '../../../../svg/ic_eye_close.svg';
import { ReactComponent as IconEyeOpen } from '../../../../svg/ic_eye_open.svg';
import { ReactComponent as IconPinCode } from '../../../../svg/ic_pin_code.svg';
import { Col, Row, snackbarSetting } from '../../../common/components/elements';
import { FreeTextField } from '../../../common/components/Form';
import { verifyForgotPassWord } from '../../redux/authThunks';
import {
  defaultParamsForgotPassword,
  defaultParamsForgotPasswordValidation,
  ParamsForgotPassword,
  Phase,
  ValidationParamsForgotPassword,
  validationParamsForgotPassword,
  validParamsForgotPassword,
} from '../../utils';

interface Props {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
  onChangePage(page: Phase): void;
  username: string;
  style?: React.CSSProperties;
}

const SetupPasswordComponent = (props: Props) => {
  const { username, onChangePage, style, dispatch } = props;
  const [params, setParams] = React.useState<ParamsForgotPassword>(defaultParamsForgotPassword);
  const [validation, setValidation] = React.useState<ValidationParamsForgotPassword>(
    defaultParamsForgotPasswordValidation,
  );

  const [ticktock, setTick] = React.useState(false);
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [seePass, setSeePass] = React.useState(false);
  const [seeVerifyPass, setSeeVerifyPass] = React.useState(false);

  const onChangePassword = React.useCallback(async () => {
    const json = await dispatch(verifyForgotPassWord({ email: username, ...params }));
    if (json?.code === SUCCESS_CODE) {
      onChangePage('login');
    }
    json?.message &&
      enqueueSnackbar(
        json?.message,
        snackbarSetting(key => closeSnackbar(key), json?.message),
      );
  }, [closeSnackbar, dispatch, enqueueSnackbar, onChangePage, params, username]);

  const validateParams = React.useCallback(() => {
    const valid = validationParamsForgotPassword(params);
    setValidation(valid);
    const errorMessage = validParamsForgotPassword(valid);
    setTick(one => !one);
    if (errorMessage) {
      enqueueSnackbar(
        errorMessage,
        snackbarSetting(key => closeSnackbar(key), intl.formatMessage({ id: errorMessage })),
      );
    } else {
      onChangePassword();
    }
  }, [closeSnackbar, enqueueSnackbar, onChangePassword, intl, params]);

  return (
    <Col key={`${ticktock}`} style={{ ...style, minWidth: 549 }}>
      <Col style={{ padding: '22px 24px 32px 24px' }}>
        <Typography variant="h5" color="textSecondary">
          <FormattedMessage id="auth.setupPassword" />
        </Typography>
        <Typography variant="subtitle2" style={{ marginTop: 12 }}>
          <FormattedMessage id="auth.senOtp" />
        </Typography>
        <Typography variant="body1" color="textSecondary" style={{ margin: '16px 0px' }}>
          <FormattedMessage id="auth.setupPasswordNote" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="userName" />
          :&nbsp;
          <Typography variant="subtitle1" component="span">
            {username}
          </Typography>
        </Typography>
        <form onSubmit={validateParams}>
          <FreeTextField
            optional
            style={{ marginTop: 8, marginRight: 0 }}
            header={intl.formatMessage({ id: 'auth.otpCode' })}
            placeholder={intl.formatMessage({ id: 'auth.initOtp' })}
            text={params.otp}
            valid={validation.otp}
            update={text => {
              setParams({ ...params, otp: text });
              setValidation({ ...validation, otp: true });
            }}
            inputProps={{ style: { minHeight: 40 }, maxLength: 10 }}
            type="password"
            endAdornment={
              <InputAdornment position="end" style={{ marginRight: 8 }}>
                <IconPinCode />
              </InputAdornment>
            }
            regex={noBlankSpaceRegex}
          />
          <FreeTextField
            optional
            style={{ marginTop: 24, marginRight: 0 }}
            header={intl.formatMessage({ id: 'newPassword' })}
            placeholder={intl.formatMessage({ id: 'initNewPassword' })}
            text={params.newPass}
            valid={validation.newPass}
            update={text => {
              setParams({ ...params, newPass: voca.latinise(text) });
              setValidation({ ...validation, newPass: true });
            }}
            inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
            type={seePass ? 'text' : 'password'}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ marginRight: 8 }}
                onClick={() => setSeePass(!seePass)}
              >
                <IconButton style={{ padding: 0 }}>
                  {seePass ? <IconEyeClose /> : <IconEyeOpen />}
                </IconButton>
              </InputAdornment>
            }
            regex={noBlankSpaceRegex}
          />
          <FreeTextField
            optional
            style={{ marginTop: 24, marginRight: 0 }}
            header={intl.formatMessage({ id: 'verifyPassword' })}
            placeholder={intl.formatMessage({ id: 'verifyPassword' })}
            text={params.verifyPass}
            valid={validation.verifyPass && validation.samePassword}
            update={text => {
              setParams({ ...params, verifyPass: voca.latinise(text) });
              setValidation({ ...validation, verifyPass: true });
            }}
            inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
            type={seeVerifyPass ? 'text' : 'password'}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ marginRight: 8 }}
                onClick={() => setSeeVerifyPass(!seeVerifyPass)}
              >
                <IconButton style={{ padding: 0 }}>
                  {seeVerifyPass ? <IconEyeClose /> : <IconEyeOpen />}
                </IconButton>
              </InputAdornment>
            }
            regex={noBlankSpaceRegex}
          />
          <Row style={{ marginTop: 48, justifyContent: 'center' }}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              size="large"
              style={{ marginRight: 24, minWidth: 144 }}
              onClick={validateParams}
            >
              <Typography variant="button">
                <FormattedMessage id="confirm" />
              </Typography>
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              style={{ minWidth: 144, background: GREY, color: 'white' }}
              onClick={() => onChangePage('login')}
            >
              <Typography variant="button">
                <FormattedMessage id="cancel" />
              </Typography>
            </Button>
          </Row>
        </form>
      </Col>
    </Col>
  );
};

export default connect()(SetupPasswordComponent);
