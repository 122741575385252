import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import {
  defaultDeviceFilterParams,
  DeviceFilterParams,
  IDeviceInfoDetail,
} from '../../../../common/models/device';
import { fetchThunk } from '../../../../common/redux/thunk';
import { removeNullParams } from '../../../constants';
import { IBannerGroupSearchParams, defaultBannerGroupSearchParams } from '../../../../common/models/banner';

export interface ManagerBannerGroupSOState {
  data?: some;
  filter: DeviceFilterParams;
  deviceInfoDetail?: IDeviceInfoDetail;
  loading: boolean;
}

export const setData = createAction('smartPos/managerBannerGroupSO/setData', (data?: some) => ({
  data,
}))();

export const setFilter = createAction(
  'smartPos/managerBannerGroupSO/setFilter',
  (data: DeviceFilterParams) => ({
    data,
  }),
)();

export const setDeviceInfoDetail = createAction(
  'smartPos/managerBannerGroupSO/setDeviceInfoDetail',
  (data?: IDeviceInfoDetail) => ({
    data,
  }),
)();
export const setLoading = createAction(
  'smartPos/managerBannerGroupSO/setLoading',
  (val: boolean) => ({
    val,
  }),
)();

export function fetchBannerGroup(
  searchParams: IBannerGroupSearchParams = defaultBannerGroupSearchParams,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    return await dispatch(
      fetchThunk(API_PATHS.getBannerGroup, 'post', JSON.stringify(removeNullParams(searchParams))),
    );
  };
}


export function fetchBannerGroupSO(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { filter } = state.smartPosSo.managerBanner.managerBannerGroupSO;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(
        API_PATHS.getDevice,
        'post',
        JSON.stringify(removeNullParams({ ...filter, typeId: 1 })), // Default máy POS
      ),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

const actions = { setData, setDeviceInfoDetail, setFilter, setLoading };

export const DEFAULT_MANAGER_BANNER_SO_STATE: ManagerBannerGroupSOState = {
  filter: defaultDeviceFilterParams,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerBannerGroupSOState = DEFAULT_MANAGER_BANNER_SO_STATE,
  action: ActionT,
): ManagerBannerGroupSOState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setFilter):
      return { ...state, filter: action.payload.data };
    case getType(setDeviceInfoDetail):
      return { ...state, deviceInfoDetail: action.payload.data };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
