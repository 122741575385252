/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { ROUTES } from '../../../configs/routes';
import { SKIP_RESET_FILTER } from '../../../constants';

interface Props extends RouteProps {
  auth: boolean;
}

const ProtectedRoute: React.SFC<Props> = props => {
  const { auth, ...restProps } = props;
  const search = new URLSearchParams(props?.location?.search);
  search.delete(SKIP_RESET_FILTER);
  const from = (props.location && `${props.location.pathname}${search.toString()}`) || '/';
  const state = props.location && props.location.state;

  if (auth) {
    return <Route {...restProps} />;
  }

  return (
    <Redirect
      to={{
        state,
        pathname: `${ROUTES.login}`,
        // search: `?from=${encodeURIComponent(from)}`,
      }}
    />
  );
};

export default ProtectedRoute;
