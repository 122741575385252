import { combineReducers } from 'redux';

import managerBannerReducer, {
  ManagerBannerState,
  DEFAULT_MANAGER_BANNER_STATE,
} from '../managerBanner/redux/reducer';
import managerDeviceReducer, {
  ManagerDeviceState,
  DEFAULT_MANAGER_DEVICE_STATE,
} from '../manageDevice/redux/managerDeviceReducer';
import configurationDeviceReducer, {
  ConfigurationDeviceState,
  DEFAULR_CONFIG_DEVICE_STATE,
} from '../configurationDevice/redux/reducer';
import managerUserPermissionReducer, {
  ManagerUserPermissionState,
  DEFAULT_MANAGER_USER_PERMISSION_STATE,
} from '../managerUserPermission/redux/reducer';
import managerTransactionReducer, {
  ManagerTransactionState,
  DEFAULT_MANAGER_TRANSACTION_STATE,
} from '../managerTransaction/redux/managerTransactionReducer';
import managerPaymentMethodReducer, {
  ManagerPaymentMethodState,
  DEFAULT_MANAGER_PAYMENT_STATE,
} from '../managePaymentMethod/redux/managerPaymentMethodReducer';

export const DEFAULR_SMARTPOS_SO_STATE: SmartPosSoState = {
  managementDevice: DEFAULT_MANAGER_DEVICE_STATE,
  configurationDevice: DEFAULR_CONFIG_DEVICE_STATE,
  managementPaymentMethod: DEFAULT_MANAGER_PAYMENT_STATE,
  managerBanner: DEFAULT_MANAGER_BANNER_STATE,
  managerUserPermission: DEFAULT_MANAGER_USER_PERMISSION_STATE,
  managerTransaction: DEFAULT_MANAGER_TRANSACTION_STATE,
};
export interface SmartPosSoState {
  managementDevice: ManagerDeviceState;
  configurationDevice: ConfigurationDeviceState;
  managementPaymentMethod: ManagerPaymentMethodState;
  managerBanner: ManagerBannerState;
  managerUserPermission: ManagerUserPermissionState;
  managerTransaction: ManagerTransactionState;
}

export default combineReducers({
  managementDevice: managerDeviceReducer,
  managerBanner: managerBannerReducer,
  configurationDevice: configurationDeviceReducer,
  managementPaymentMethod: managerPaymentMethodReducer,
  managerUserPermission: managerUserPermissionReducer,
  managerTransaction: managerTransactionReducer,
});
