import { combineReducers } from 'redux';

import managerParameterReducer, {
  managerParameterState,
  DEFAULT_MANAGER_PARAMETER_STATE,
} from '../managerParameter/redux/managerParameterReducer';
import managerStatusReducer, {
  managerStatusState,
  DEFAULT_MANAGER_STATUS_STATE,
} from '../managerStatus/redux/managerStatusReducer';
import managerErrorReducer, {
  managerErrorState,
  DEFAULT_MANAGER_ERROR_STATE,
} from '../managerError/redux/managerErrorReducer';

export const DEFAULR_CONFIG_DEVICE_STATE: ConfigurationDeviceState = {
  managerParameter: DEFAULT_MANAGER_PARAMETER_STATE,
  managerStatus: DEFAULT_MANAGER_STATUS_STATE,
  managerError: DEFAULT_MANAGER_ERROR_STATE,
};

export interface ConfigurationDeviceState {
  managerParameter: managerParameterState;
  managerStatus: managerStatusState;
  managerError: managerErrorState;
}

export default combineReducers({
  managerParameter: managerParameterReducer,
  managerStatus: managerStatusReducer,
  managerError: managerErrorReducer,
});
