import * as React from 'react';
import LanguageSelect from '../../intl/components/LanguageSelect';
import UserInfoDropdown from './UserInfoDropdown';
import { DEV } from '../../../constants';

interface Props { }

const Badge: React.FunctionComponent<Props> = props => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        flexShrink: 0,
        flexGrow: 1,
        position: 'relative',
      }}
    >
      {DEV == 'true' && <LanguageSelect />}
      {/* <NotificationDropdown light={light} /> */}
      <UserInfoDropdown />
    </div>
  );
};

export default Badge;
