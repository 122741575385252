import { some } from '../../constants';

/* --------------DialogChangepassword---------------*/
export interface ValidationAccount {
  name: boolean;
  phoneNumber: boolean;
}

export const defaultValidationAccountInfo: ValidationAccount = {
  name: true,
  phoneNumber: true,
};

export function validationAccountInfo(userInfo: some): ValidationAccount {
  const name = userInfo.name ? userInfo?.name?.trim() : false;
  const phoneNumber = userInfo.phoneNumber ? !!userInfo?.phoneNumber?.trim() : false;
  return { name, phoneNumber };
}

export function validAccountInfo(validation: ValidationAccount) {
  if (!validation.name) {
    return 'managerAccount.error.name';
  }
  if (!validation.phoneNumber) {
    return 'managerAccount.error.phoneNumber';
  }
  return '';
}

export interface ParamsChangePassword {
  oldPass: string;
  newPass: string;
  verifyPass: string;
}
export const defaultParamsChangePassword: ParamsChangePassword = {
  oldPass: '',
  newPass: '',
  verifyPass: '',
};
export interface ValidationParamsChangePassword {
  oldPass: boolean;
  newPass: boolean;
  verifyPass: boolean;
  samePassword: boolean;
  passValid: boolean;
}
export const defaultParamsChangePasswordValidation: ValidationParamsChangePassword = {
  oldPass: true,
  newPass: true,
  verifyPass: true,
  samePassword: true,
  passValid: true,
};

export function validationParamsChangePassword(
  params: ParamsChangePassword,
): ValidationParamsChangePassword {
  const oldPass = params.oldPass ? !!params?.oldPass?.trim() : false;
  const newPass = params.newPass ? !!params?.newPass?.trim() : false;
  const verifyPass = params.verifyPass ? !!params?.verifyPass?.trim() : false;
  const samePassword = params.newPass === params.verifyPass;
  const passValid =
    params?.oldPass?.length >= 6 && params?.newPass?.length >= 6 && params?.verifyPass?.length >= 6;
  return { oldPass, newPass, verifyPass, samePassword, passValid };
}

export function validParamsChangePassword(validation: ValidationParamsChangePassword) {
  if (!validation.oldPass) {
    return 'managerAccount.error.oldPass';
  }
  if (!validation.newPass) {
    return 'managerAccount.error.newPass';
  }
  if (!validation.verifyPass) {
    return 'managerAccount.error.verifyPass';
  }
  if (!validation.samePassword) {
    return 'managerAccount.error.samePassword';
  }
  if (!validation.passValid) {
    return 'managerAccount.error.passwordValid';
  }
  return '';
}
