import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../constants';
import { AppState } from '../../../../redux/reducers';
import {
  defaultDeviceFilterParams,
  DeviceFilterParams,
  DeviceInfo,
} from '../../../common/models/device';
import { fetchThunk } from '../../../common/redux/thunk';
import { removeNullParams } from '../../constants';

export interface ManagerDeviceState {
  data?: some;
  params: DeviceFilterParams;
  loading: boolean;
}

export const setData = createAction('smartPos/managerDevice/setData', (data?: some) => ({
  data,
}))();

export const setParams = createAction(
  'smartPos/managerDevice/setParams',
  (params: DeviceFilterParams) => ({
    params,
  }),
)();

export const setLoading = createAction('smartPos/managerDevice/setLoading', (val: boolean) => ({
  val,
}))();

export function fetchMangeDeviceData(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { params } = state.smartPosSo.managementDevice;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getDevice, 'post', JSON.stringify(removeNullParams(params))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

export function onUpdateDevice(
  info: DeviceInfo,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.updateDevice, 'post', JSON.stringify(removeNullParams(info))),
    );
    return json;
  };
}

export function onCreateDevice(
  info: DeviceInfo,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.addDevice, 'post', JSON.stringify(removeNullParams(info))),
    );
    return json;
  };
}

const actions = { setData, setParams, setLoading };
export const DEFAULT_MANAGER_DEVICE_STATE: ManagerDeviceState = {
  params: defaultDeviceFilterParams,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerDeviceState = DEFAULT_MANAGER_DEVICE_STATE,
  action: ActionT,
): ManagerDeviceState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setParams):
      return { ...state, params: action.payload.params };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
