import { Button, Collapse, Typography } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { GREY, LIGHT_GREY } from '../../../configs/colors';
import { some } from '../../../constants';
import { HEADER_HEIGHT } from '../../../layout/constants';
import { AppState } from '../../../redux/reducers';
import iconFlagEn from '../../../svg/flag_en.svg';
import iconFlagVi from '../../../svg/flag_vi.svg';
import { Col } from '../../common/components/elements';
import { setLocale } from '../redux/reducer';

const Tip = styled.div`
  display: flex;
  align-items: flex-right;
  justify-content: flex-start;
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  &:hover {
    background: ${LIGHT_GREY};
  }
  cursor: pointer;
`;

const DATA = [
  { src: iconFlagVi, value: 'vi', label: 'Tiếng Việt' },
  { src: iconFlagEn, value: 'en-US', label: 'English' },
];

function mapStateToProps(state: AppState) {
  return {
    locale: state.intl.locale,
  };
}

interface Props extends ReturnType<typeof mapStateToProps> {
  dispatch: Dispatch;
}
const LanguageSelect: React.FC<Props> = props => {
  const { dispatch, locale } = props;
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState<some>(DATA.find(one => one.value === locale) || DATA[0]);

  const onBlur = React.useCallback((e: React.FocusEvent<HTMLDivElement>) => {
    if (e.relatedTarget instanceof Element) {
      if (e.currentTarget.contains(e.relatedTarget as Element)) {
        return;
      }
    }
    setOpen(false);
  }, []);

  return (
    <Col
      style={{
        alignItems: 'center',
        marginRight: 16,
        outline: 'none',
      }}
      color="inherit"
      tabIndex={-1}
      onClick={() => setOpen(!open)}
      onBlur={onBlur}
    >
      <Button
        disableElevation
        style={{
          padding: '0px 18px',
        }}
        color="inherit"
      >
        <img src={flag.src} alt="" style={{ width: '24px', height: '16px', objectFit: 'cover' }} />
        &nbsp;&nbsp;<Typography variant="caption">{flag.label}</Typography>
        <Typography style={{ marginLeft: '8px' }}>
          <span
            style={{
              display: 'inline-block',
              fontSize: '10px',
              marginBottom: '4px',
              transition: 'all 300ms',
              transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
              cursor: 'pointer',
            }}
          >
            &#9650;
          </span>
        </Typography>
      </Button>
      <Collapse
        in={open}
        style={{
          position: 'absolute',
          color: 'black',
          zIndex: 1100,
          background: 'white',
          top: HEADER_HEIGHT,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          border: `1px solid ${GREY}`,
          borderRadius: '4px',
        }}
      >
        <div style={{ padding: '8px 12px' }}>
          {DATA.map((v: some, index: number) => {
            return (
              <Tip
                key={index}
                onClick={() => {
                  setFlag(v);
                  dispatch(setLocale(v.value));
                }}
              >
                <img
                  src={v.src}
                  alt=""
                  style={{ width: '38px', height: '25px', objectFit: 'cover' }}
                />
                &nbsp;&nbsp;&nbsp;
                <Typography variant="body2">{v.label}</Typography>
              </Tip>
            );
          })}
        </div>
      </Collapse>
    </Col>
  );
};

export default connect(mapStateToProps)(LanguageSelect);
