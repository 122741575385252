import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../redux/reducers';
import { setLoginErrorMsg } from '../../redux/authReducer';
import { Phase } from '../../utils';
import ForgotPassword from './ForgotPasswordComponent';
import LoginComponent from './LoginComponent';
import SetupPasswordComponent from './SetupPasswordComponent';
import { USER_NAME } from '../../constants';

const mapState2Props = (state: AppState) => {
  return {
    router: state.router,
  };
};
export interface Props extends WrappedComponentProps, ReturnType<typeof mapState2Props> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

export interface State {
  username: string;
  password: string;
}

const LoginBox: React.FunctionComponent<Props> = props => {
  const { dispatch, router } = props;
  const [usernameTemp, setUsernameTemp] = React.useState('');
  const [phase, setPhase] = React.useState<Phase>('login');

  React.useEffect(() => {
    if (phase === 'login') {
      dispatch(setLoginErrorMsg());
      setUsernameTemp('');
    }
  }, [dispatch, phase]);

  React.useEffect(() => {
    const search = new URLSearchParams(router.location.search);
    const username = search.get(USER_NAME);
    if (username) {
      setUsernameTemp(username);
      setPhase('setupPassword');
    }
  }, [router.location.search]);
  
  return (
    <div>
      {phase === 'login' && <LoginComponent onChangePage={(page: Phase) => setPhase(page)} />}
      {phase === 'forgotPassword' && (
        <ForgotPassword
          username={usernameTemp}
          onChangePage={(page: Phase) => setPhase(page)}
          onChange={userName => {
            setUsernameTemp(userName);
          }}
        />
      )}
      {phase === 'setupPassword' && (
        <SetupPasswordComponent
          username={usernameTemp}
          onChangePage={(page: Phase) => setPhase(page)}
        />
      )}
    </div>
  );
};

export default connect(mapState2Props)(injectIntl(LoginBox));
