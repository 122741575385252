export interface IBannerGroupImage {
  id?: number;
  urlImage: string;
  status: number;
}

export interface IBannerGroup {
  id?: number;
  name: string;
  status: number;
  listBanner: IBannerGroupImage[];
  validation: boolean;
}

export interface IBannerGroupSearchParams {
  name: string;
  status: number;
}

export const defaultBannerGroup: IBannerGroup = {
  name: '',
  status: 1,
  listBanner: [],
  validation: true,
};

export const defaultBannerGroupSearchParams: IBannerGroupSearchParams = {
  name: '',
  status: -1,
};
