import React from 'react';
import styled from 'styled-components';
import {
  withStyles,
  InputBase,
  fade,
  Theme,
  SnackbarContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import { OptionsObject } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { GREY } from '../../../configs/colors';
import { HEADER_HEIGHT } from '../../../layout/constants';

export const PageWrapper = styled.div`
  min-height: ${window.innerHeight - HEADER_HEIGHT}px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 300ms;
  min-width: 100%;
  overflow: hidden;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BootstrapInput = withStyles((theme: Theme) => ({
  '@keyframes vibrate': {
    '0%': {
      left: '-8px',
    },
    '50%': {
      left: '8px',
    },
    '100%': {
      left: '-8px',
    },
  },
  root: {
    borderRadius: 4,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: `${GREY}`,
    position: 'relative',
  },
  input: {
    borderRadius: 4,
    backgroundColor: 'white',
    padding: '5px 12px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '34px',
    fontSize: `${theme.typography.body2.fontSize}`,
    boxSizing: 'border-box',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&::placeholder': {
      color: GREY,
      opacity: 1,
    },
  },
  error: {
    boxShadow: `${fade(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.error.main,
    animationName: '$vibrate',
    animationDuration: '175ms',
    animationTimingFunction: `${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 5,
  },
  focused: {
    boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}))(InputBase);

export const BootstrapInputNoVibrate = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: `${GREY}`,
    position: 'relative',
  },
  input: {
    borderRadius: 4,
    backgroundColor: 'white',
    padding: '5px 12px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '34px',
    fontSize: `${theme.typography.body2.fontSize}`,
    boxSizing: 'border-box',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&::placeholder': {
      color: GREY,
      opacity: 1,
    },
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  focused: {
    boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    borderColor: theme.palette.primary.main,
  },
}))(InputBase);

export function snackbarSetting(closeSnackbar: (key: string) => void, message?: React.ReactNode) {
  return {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    preventDuplicate: true,
    autoHideDuration: 2000,
    content: (key: string) => (
      <SnackbarContent
        id={key}
        style={{
          background: 'white',
          boxShadow:
            '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14)',
          borderRadius: '8px',
          minWidth: '250px',
          display: message ? undefined : 'none',
        }}
        aria-describedby="client-snackbar"
        message={
          <Typography variant="body2" color="textPrimary">
            {message}
          </Typography>
        }
        action={[
          <IconButton key="close" aria-label="close" onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    ),
  } as OptionsObject;
}
