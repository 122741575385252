import React from 'react';
import AccountInfoDesktop from '../component/AccountInfoDesktop';

interface Props {}

const AccountInfo: React.FunctionComponent<Props> = props => {
  return <AccountInfoDesktop />;
};

export default AccountInfo;
