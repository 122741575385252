import { Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { some, validTelephoneRegex, specialCharacterRegex } from '../../../constants';
import '../../../scss/svg.scss';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { Row, snackbarSetting } from '../../common/components/elements';
import { FreeTextField } from '../../common/components/Form';
import {
  defaultValidationAccountInfo,
  validAccountInfo,
  ValidationAccount,
  validationAccountInfo,
} from '../utils';

interface Props {
  onUpdate(info: some): void;
  onClose(): void;
  data: some;
}

const UpdateAccountDesktop: FC<Props> = ({ onUpdate, onClose, data }) => {
  const [info, setInfo] = useState(data);
  const [validation, setValidation] = useState<ValidationAccount>(
    defaultValidationAccountInfo,
  );
  const intl = useIntl();
  const [ticktock, setTick] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setInfo(data);
  }, [data]);

  const onContinue = useCallback(() => {
    if (info) {
      const valid = validationAccountInfo(info);
      setValidation(valid);
      setTick(one => !one);
      const errorMessage = validAccountInfo(valid);
      if (errorMessage) {
        enqueueSnackbar(
          errorMessage,
          snackbarSetting(key => closeSnackbar(key), intl.formatMessage({ id: errorMessage })),
        );
      } else {
        setConfirmSave(true);
      }
    }
  }, [closeSnackbar, enqueueSnackbar, info, intl]);
  if (!info) {
    return null;
  }
  return (
    <div style={{ marginTop: 32, width: 270 }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Typography style={{ height: 28, marginRight: 32 }} variant="body2">
          <FormattedMessage id="managerAccount.email" />
        </Typography>
        <Typography style={{ height: 28 }} variant="subtitle2">
          {info.email}
        </Typography>
      </Row>
      <div key={`${ticktock}`}>
        <FreeTextField
          style={{ marginTop: 24, marginRight: 0 }}
          header={intl.formatMessage({ id: 'managerAccount.userName' })}
          text={info.name}
          valid={validation.name}
          headerStyle={{ marginLeft: 0 }}
          inputProps={{ maxLength: 50 }}
          update={text => {
            if (!specialCharacterRegex.test(text)) {
              setInfo({ ...info, name: text });
              setValidation({ ...validation, name: true });
            }
          }}
        />
        <FreeTextField
          headerStyle={{ marginLeft: 0 }}
          style={{ marginTop: 24, marginRight: 0 }}
          header={intl.formatMessage({ id: 'managerAccount.phoneNumber' })}
          text={info.phoneNumber}
          valid={validation.phoneNumber}
          inputProps={{ maxLength: 12 }}
          regex={validTelephoneRegex}
          update={text => {
            if (!specialCharacterRegex.test(text)) {
              setInfo({ ...info, phoneNumber: text });
              setValidation({ ...validation, phoneNumber: true });
            }
          }}
        />
      </div>
      <Row style={{ justifyContent: 'space-between', marginTop: 28 }}>
        <Typography style={{ height: 28, marginRight: 32 }} variant="body2">
          <FormattedMessage id="managerAccount.roleGroup" />
        </Typography>
        <Typography style={{ height: 28 }} variant="body2">
          {info?.roleGroup?.roleName}
        </Typography>
      </Row>
      <Row style={{ marginTop: 32 }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          style={{ marginRight: 24, minWidth: 144 }}
          onClick={onContinue}
        >
          <Typography variant="button">
            <FormattedMessage id="confirm" />
          </Typography>
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          style={{ minWidth: 144 }}
          onClick={onClose}
        >
          <Typography variant="button">
            <FormattedMessage id="cancel" />
          </Typography>
        </Button>
      </Row>
      <ConfirmDialog
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        onAccept={() => {
          onUpdate(info);
          setConfirmSave(false);
        }}
        onReject={() => setConfirmSave(false)}
        acceptLabel="save"
        rejectLabel="pass"
      >
        <Typography variant="body1">
          <FormattedMessage id="managerAccount.confirmSave" />
        </Typography>
      </ConfirmDialog>
    </div>
  );
};

export default UpdateAccountDesktop;
