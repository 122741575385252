import { some , PAGE_SIZE } from "../../../constants";


export interface IDeviceType {
  id: number;
  name: string;
}

export interface IArea extends IDeviceType {}
export interface IPlace extends IDeviceType {}
export interface IBannerGroup extends IDeviceType {}

export interface IDeviceInfoDetail {
  id: number;
  deviceType: IDeviceType;
  deviceId: string;
  area?: IArea;
  place?: IPlace;
  status: number;
  statusPaper: string;
  bannerGroup?: IBannerGroup;
  connect: boolean;
}

export interface DeviceInfo {
  id?: number;
  typeId?: number;
  areaId?: number;
  placeId?: number;
  deviceId?: string;
  status: number;
  deviceConnect?: some;
  deviceConnectId?: number | null;
}

export const defaultDeviceInfo: DeviceInfo = {
  status: 1,
};

export interface DeviceInfoValidation {
  id: boolean;
  typeId: boolean;
  areaId: boolean;
  placeId: boolean;
  deviceId: boolean;
  status: boolean;
  deviceConnectId: boolean;
}

export const defaultDeviceInfoValidation: DeviceInfoValidation = {
  id: true,
  typeId: true,
  areaId: true,
  placeId: true,
  deviceId: true,
  status: true,
  deviceConnectId: true,
};

export interface DeviceFilterParams {
  typeId: number;
  status: number;
  placeId: number;
  areaId: number;
  connect: number;
  connectSmartPos: number;
  statusPaper: number;
  deviceId: string;
  bannerGroupName: string;
  name: string;
  pageSize: number;
  pageOffset: number;
}

export const defaultDeviceFilterParams: DeviceFilterParams = {
  typeId: -1,
  status: -1,
  placeId: -1,
  areaId: -1,
  connect: -1,
  connectSmartPos: -1,
  statusPaper: -1,
  deviceId: '',
  bannerGroupName: '',
  name: '',
  pageSize: PAGE_SIZE,
  pageOffset: 0,
};
