import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import moment from 'moment';
import { API_PATHS } from '../../../../configs/API';
import { some } from '../../../../constants';
import { AppState } from '../../../../redux/reducers';
import {
  defaultTransactionFilterParams,
  ITransactionFilterParams,
} from '../../../common/models/transaction';
import { fetchThunk } from '../../../common/redux/thunk';
import { removeNullParams } from '../../constants';
import { DATE_TIME_FORMAT, DATE_TIME_BACKEND_FORMAT } from '../../../common/constants';

export interface ManagerTransactionState {
  filter: ITransactionFilterParams;
}

export const setFilter = createAction(
  'smartPos/managerTransaction/setFilter',
  (data: ITransactionFilterParams) => ({
    data,
  }),
)();

export function fetchTransaction(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const { filter } = getState().smartPosSo.managerTransaction;

    return await dispatch(
      fetchThunk(
        API_PATHS.getTransaction,
        'post',
        JSON.stringify(
          removeNullParams({
            ...filter,
            fromDate: filter.fromDate
              ? moment(filter.fromDate, DATE_TIME_FORMAT).format(DATE_TIME_BACKEND_FORMAT)
              : undefined,
            toDate: filter.toDate
              ? moment(filter.toDate, DATE_TIME_FORMAT).format(DATE_TIME_BACKEND_FORMAT)
              : undefined,
          }),
        ),
      ),
    );
  };
}

const actions = { setFilter };

export const DEFAULT_MANAGER_TRANSACTION_STATE: ManagerTransactionState = {
  filter: defaultTransactionFilterParams,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerTransactionState = DEFAULT_MANAGER_TRANSACTION_STATE,
  action: ActionT,
): ManagerTransactionState {
  switch (action.type) {
    case getType(setFilter):
      return { ...state, filter: action.payload.data };
    default:
      return state;
  }
}
