import * as React from 'react';
import { ReactComponent as IconNotFound } from '../../../svg/ic_404.svg';
import { Row } from './elements';

export interface Props {}

export default function NotFound(props: Props) {
  return (
    <Row style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      <IconNotFound />
    </Row>
  );
}
