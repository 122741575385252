import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import { fetchThunk } from '../../../../common/redux/thunk';
import { ParameterDeviceInfo } from '../utils';
import { removeNullParams } from '../../../constants';
import { DeviceFilterParams, defaultDeviceFilterParams } from '../../../../common/models/device';

export interface managerParameterState {
  data?: some;
  params: DeviceFilterParams;
  loading: boolean;
}

export const setData = createAction('smartPos/m/setData', (data?: some) => ({
  data,
}))();

export const setParams = createAction('smartPos/m/setParams', (params: DeviceFilterParams) => ({
  params,
}))();

export const setLoading = createAction('smartPos/m/setLoading', (val: boolean) => ({
  val,
}))();

export function fetchMangeParameterData(): ThunkAction<
  Promise<some>,
  AppState,
  null,
  Action<string>
> {
  return async (dispatch, getState) => {
    const state = getState();
    const { params } = state.smartPosSo.configurationDevice.managerParameter;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getParameter, 'post', JSON.stringify(removeNullParams(params))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

export function onUpdateParameter(
  info: ParameterDeviceInfo,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.updateParameter, 'post', JSON.stringify(info)),
    );
    return json;
  };
}

const actions = { setData, setParams, setLoading };
export const DEFAULT_MANAGER_PARAMETER_STATE: managerParameterState = {
  params: defaultDeviceFilterParams,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: managerParameterState = DEFAULT_MANAGER_PARAMETER_STATE,
  action: ActionT,
): managerParameterState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setParams):
      return { ...state, params: action.payload.params };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
