import { Divider, Typography } from '@material-ui/core';
import { go } from 'connected-react-router';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SECONDARY } from '../../configs/colors';
import { ROUTES_TAB } from '../../configs/routes';
import { some } from '../../constants';
import { Row } from '../../modules/common/components/elements';
import Link from '../../modules/common/components/Link';
import { isHasPermission } from '../../modules/common/redux/reducer';
import { AppState } from '../../redux/reducers';
import { getListRoutesContain } from '../utils';

const mapStateToProps = (state: AppState) => {
  return {
    location: state.router.location,
  };
};

interface Props extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

const DefaultBreadcrumbs: React.FC<Props> = props => {
  const { location, dispatch } = props;
  const { pathname } = location;
  const state = location.state as { [key: string]: any };
  const intl = useIntl();

  const isActive = React.useMemo(() => {
    return dispatch(isHasPermission(pathname));
  }, [dispatch, pathname]);

  const getList = React.useMemo(() => {
    return getListRoutesContain(ROUTES_TAB, pathname);
  }, [pathname]);

  const isBackAble = React.useCallback(
    (value: some): any => {
      return state && state[`${value.path}`];
    },
    [state],
  );
  const getTitle = React.useMemo(() => {
    const currentPath = getList[0];
    if (currentPath && (currentPath.title || currentPath.name)) {
      return currentPath.title || currentPath.name;
    }
    return null;
  }, [getList]);

  if (!isActive) {
    return null;
  }
  return (
    <div>
      <Helmet>
        <title>{getTitle && intl.formatMessage({ id: getTitle })}</title>
      </Helmet>
      <Row style={{ marginBottom: 8 }}>
        {[...getList].reverse().map((v: some, index: number) => (
          <div key={index}>
            {index === getList.length - 1 ? (
              <Typography variant="body2">
                {(v.title || v.name) && <FormattedMessage id={v.title || v.name} />}
              </Typography>
            ) : (
              <>
                {v.path || !v.isModule ? (
                  <>
                    {isBackAble(v) ? (
                      <Typography
                        variant="subtitle2"
                        style={{ color: SECONDARY, cursor: 'pointer' }}
                        onClick={() => dispatch(go(-v.backStep))}
                      >
                        {(v.title || v.name) && (
                          <>
                            <FormattedMessage id={v.title || v.name} /> &nbsp;/&nbsp;
                          </>
                        )}
                      </Typography>
                    ) : (
                      <Link to={{ pathname: v.path, state: { ...state, [`${v.path}`]: true } }}>
                        <Typography variant="subtitle2" style={{ color: SECONDARY }}>
                          {(v.title || v.name) && (
                            <>
                              <FormattedMessage id={v.title || v.name} /> &nbsp;/&nbsp;
                            </>
                          )}
                        </Typography>
                      </Link>
                    )}
                  </>
                ) : (
                  <Typography variant="subtitle2" style={{ color: SECONDARY }}>
                    {(v.title || v.name) && (
                      <>
                        <FormattedMessage id={v.title || v.name} /> &nbsp;/&nbsp;
                      </>
                    )}
                  </Typography>
                )}
              </>
            )}
          </div>
        ))}
      </Row>
      <Typography variant="h5">
        <FormattedMessage id={getTitle || ' '} />
      </Typography>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
    </div>
  );
};

export default connect(mapStateToProps)(DefaultBreadcrumbs);
