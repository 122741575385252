import moment from 'moment';
import { DATE_TIME_FORMAT } from '../constants';

export interface ITransactionFilterParams {
  billNumber?: string;
  bankCode?: string;
  deviceTypeId: number;
  areaId?: number;
  placeId?: number;
  bookingId?: string;
  pnr?: string;
  deviceId?: string;
  paymentStatus: string;
  paymentMethodCode: string;
  bookingStatus: string;
  fromDate?: string;
  toDate?: string;
  pageSize: number;
  pageOffset: number;
}

export const defaultTransactionFilterParams: ITransactionFilterParams = {
  bankCode: '',
  deviceTypeId: -1,
  areaId: -1,
  placeId: -1,
  fromDate: moment().startOf('day').format(DATE_TIME_FORMAT),
  toDate: moment().format(DATE_TIME_FORMAT),
  pageOffset: 0,
  pageSize: 10,
  paymentStatus: '-1',
  paymentMethodCode: '-1',
  bookingStatus: '-1',
};
