import { goBack, push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import _ from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../configs/API';
import { ROUTES_TAB } from '../../../configs/routes';
import { Role, some, SUCCESS_CODE } from '../../../constants';
import { getCurrentRole, getListRoutesContain } from '../../../layout/utils';
import { AppState } from '../../../redux/reducers';
import { fetchThunk } from './thunk';

export interface CommonState {
  networkErrorMsg: string;
  authErrorMsg: string;
  errorMessage: string;
  generalLocale: some[];
  generalDeviceType: some[];
  generalPaymentMethod: some[];
  openErrorDialog: boolean;
}
export const setOpenErrorDialog = createAction('common/setOpenErrorDialog', (val: boolean) => ({
  val,
}))();

export const setNetworkError = createAction(
  'common/setNetworkError',
  (val: string, open: boolean) => ({ val, open }),
)();
export const setAuthError = createAction('common/setAuthError', (val: string) => ({ val }))();

export const setErrorMessage = createAction('common/setErrorMessage', (val: string) => ({ val }))();

export const setGeneralLocale = createAction('common/setGeneralLocale', (val: some[]) => ({
  val,
}))();
export const setGeneralDeviceType = createAction('common/setGeneralDeviceType', (val: some[]) => ({
  val,
}))();
export const setGeneralPaymentMethod = createAction(
  'common/setGeneralPaymentMethod',
  (val: some[]) => ({
    val,
  }),
)();

export function isHasPermission(
  permission?: Role[] | string,
): ThunkAction<boolean, AppState, null, Action<string>> {
  return (dispatch, getState) => {
    const state = getState();
    const { account } = state;
    return getCurrentRole(account.userData?.roleGroup?.listFeature, permission);
  };
}

export function goBackAction(): ThunkAction<Promise<void>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { router } = state;
    const listRoutes = getListRoutesContain(ROUTES_TAB, router.location.pathname);
    const backAble =
      listRoutes[1] && router?.location?.state
        ? (router?.location?.state as some)[`${listRoutes[1].path}`]
        : false;
    if (backAble) {
      dispatch(goBack());
    } else {
      dispatch(push({ pathname: listRoutes[1].path, state: router.location.state }));
    }
  };
}

export function goToAction(
  location: LocationDescriptorObject,
): ThunkAction<Promise<void>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { router } = state;
    dispatch(
      push({
        state: {
          ..._.get(router, 'location.state', {}),
          [`${router.location.pathname}`]: true,
          ...(_.get(location, 'state', {}) as Object),
        },
        ...location,
      }),
    );
  };
}

export function getGeneralLocale(): ThunkAction<Promise<void>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {

    const fetchLocale = dispatch(fetchThunk(API_PATHS.getLocale, 'get'));
    const fetchDeviceType = dispatch(fetchThunk(`${API_PATHS.getDeviceType}`, 'post'));
    const fetchAllPaymentMethod = dispatch(fetchThunk(`${API_PATHS.getAllPaymentMethod}`));

    const localeJson = await fetchLocale;
    const deviceJson = await fetchDeviceType;
    const paymenetJson = await fetchAllPaymentMethod;

    if (localeJson?.code === SUCCESS_CODE) {
      dispatch(setGeneralLocale(localeJson.data));
    }
    if (deviceJson?.code === SUCCESS_CODE) {
      dispatch(setGeneralDeviceType(deviceJson.data));
    }
    if (paymenetJson?.code === SUCCESS_CODE) {
      dispatch(setGeneralPaymentMethod(paymenetJson.data));
    }
  };
}

export const DEFAULT_COMMON_STATE: CommonState = {
  openErrorDialog: false,
  networkErrorMsg: '',
  authErrorMsg: '',
  errorMessage: '',
  generalLocale: [],
  generalDeviceType: [],
  generalPaymentMethod: [],
};
const actions = {
  setNetworkError,
  setErrorMessage,
  setGeneralLocale,
  setGeneralPaymentMethod,
  setGeneralDeviceType,
  setOpenErrorDialog,
  setAuthError,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: CommonState = DEFAULT_COMMON_STATE,
  action: ActionT,
): CommonState {
  switch (action.type) {
    case getType(setOpenErrorDialog):
      return { ...state, openErrorDialog: action.payload.val };
    case getType(setAuthError):
      return { ...state, authErrorMsg: action.payload.val };
    case getType(setErrorMessage):
      return { ...state, errorMessage: action.payload.val };
    case getType(setNetworkError):
      return {
        ...state,
        networkErrorMsg: action.payload.val,
        openErrorDialog: action.payload.open,
      };
    case getType(setGeneralLocale):
      return { ...state, generalLocale: action.payload.val };
    case getType(setGeneralPaymentMethod):
      return { ...state, generalPaymentMethod: action.payload.val };
    case getType(setGeneralDeviceType):
      return { ...state, generalDeviceType: action.payload.val };
    default:
      return state;
  }
}
