import { Button, Dialog, IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/CloseOutlined';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row } from './elements';

interface Props {
  open: boolean;
  onClose(): void;
  onAccept(): void;
  onReject?: () => void;
  acceptLabel?: string;
  rejectLabel?: string;
  onExited?: () => void;
}

const ConfirmDialog: React.FC<Props> = props => {
  const { open, onClose, onExited, onAccept, onReject, acceptLabel, rejectLabel, children } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: '24px 24px 12px',
          minWidth: 450,
        },
      }}
      maxWidth="lg"
      onExited={onExited}
    >
      <IconButton
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: '8px',
        }}
        onClick={onClose}
      >
        <IconClose />
      </IconButton>
      {children}
      <Row style={{ justifyContent: 'center', marginTop: 20 }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          size="large"
          style={{ minWidth: 140, margin: '8px' }}
          onClick={onAccept}
        >
          <FormattedMessage id={acceptLabel || 'accept'} />
        </Button>
        {onReject && (
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="large"
            style={{ minWidth: 140, margin: '8px' }}
            onClick={onReject}
          >
            <FormattedMessage id={rejectLabel || 'cancel'} />
          </Button>
        )}
      </Row>
    </Dialog>
  );
};

export default ConfirmDialog;
