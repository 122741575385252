// /* eslint-disable no-nested-ternary */

// enum ApiService {
//   smartPosSO,
//   account,
// }

// function getBaseUrl(service: ApiService) {
//   if (service === ApiService.smartPosSO) {
//     if (DEV) {
//       return 'http://localhost:9011/back_office_sun';
//     }
//     if (TEST) {
//       return 'https://sunworld.dev.tripi.vn/back_office_sun';
//     }
//     if (STAGING) {
//       return 'https://sunworld.stage.tripi.vn/back_office_sun';
//     }
//     return 'https://sunworld-api.tripi.vn/back_office_sun';
//   }
//   return '';
// }



/* eslint-disable no-nested-ternary */

function getBaseUrl() {
  // return 'https://api.sunworld.rocket.nexpando.com/back_office_sun'
  return process.env.REACT_APP_BASE_URL
}

export const API_PATHS = {
  getBannerGroup: `${getBaseUrl()}/getBannerGroup`,
  uploadImage: `${getBaseUrl()}/uploadImage`,
  updateBannerGroup: `${getBaseUrl()}/updateBannerGroup`,
  addBannerGroup: `${getBaseUrl()}/addBannerGroup`,
  deleteBannerGroup: `${getBaseUrl()}/deleteBannerGroup`,

  getTransaction: `${getBaseUrl()}/getTransaction`,
  getTransactionDetail: `${getBaseUrl()}/getTransactionDetail`,
  exportTransaction: `${getBaseUrl()}/exportTransaction`,

  getDevice: `${getBaseUrl()}/getDevice`,
  getDeviceSmartPos: `${getBaseUrl()}/getDeviceSmartPos`,
  updateDevice: `${getBaseUrl()}/updateDevice`,
  addDevice: `${getBaseUrl()}/addDevice`,
  getParameter: `${getBaseUrl()}/getParameter`,
  updateParameter: `${getBaseUrl()}/updateParameter`,
  getDeviceLogError: `${getBaseUrl()}/getDeviceLogError`,
  getPaymentMethod: `${getBaseUrl()}/getPaymentMethod`,
  updatePaymentMethod: `${getBaseUrl()}/updatePaymentMethod`,

  login: `${getBaseUrl()}/login`,
  logout: `${getBaseUrl()}/logout`,
  forgetPass: `${getBaseUrl()}/forgetPass`,
  verifyForgetPass: `${getBaseUrl()}/verifyForgetPass`,
  getLocale: `${getBaseUrl()}/getLocale`,
  getDeviceType: `${getBaseUrl()}/getDeviceType`,
  getAllPaymentMethod: `${getBaseUrl()}/getAllPaymentMethod`,
  getUserInfo: `${getBaseUrl()}/getUserInfo`,

  getFeature: `${getBaseUrl()}/getFeature`,
  getAllRoleGroup: `${getBaseUrl()}/getAllRoleGroup`,
  getRoleGroup: `${getBaseUrl()}/getRoleGroup`,
  addRoleGroup: `${getBaseUrl()}/addRoleGroup`,
  updateRoleGroup: `${getBaseUrl()}/updateRoleGroup`,

  getListUser: `${getBaseUrl()}/getListUser`,
  registerUser: `${getBaseUrl()}/registerUser`,
  updateUser: `${getBaseUrl()}/updateUser`,

  changePass: `${getBaseUrl()}/changePass`,

  retryBooking: `${getBaseUrl()}/retryBooking`,

};
