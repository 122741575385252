import { createMuiTheme, darken, fade } from '@material-ui/core/styles';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import { BLACK_TEXT, LIGHT_GREY, PRIMARY, SECONDARY } from './colors';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: SECONDARY,
        backgroundColor_active: SECONDARY,
        backgroundColor_hover: SECONDARY,
        borderColor: SECONDARY,
        borderColor_active: SECONDARY,
        borderColor_hover: SECONDARY,
      },
      selectedSpan: {
        backgroundColor: fade(SECONDARY, 0.2),
        backgroundColor_active: fade(SECONDARY, 0.2),
        backgroundColor_hover: fade(SECONDARY, 0.2),
        borderColor: fade(SECONDARY, 0.2),
        borderColor_active: fade(SECONDARY, 0.2),
        borderColor_hover: fade(SECONDARY, 0.2),
      },
      hoveredSpan: {
        backgroundColor: fade(SECONDARY, 0.2),
        backgroundColor_active: fade(SECONDARY, 0.2),
        backgroundColor_hover: fade(SECONDARY, 0.2),
        borderColor: fade(SECONDARY, 0.2),
        borderColor_active: fade(SECONDARY, 0.2),
        borderColor_hover: fade(SECONDARY, 0.2),
      },
    },
  },
});

export const THEME = {
  primary: PRIMARY,
  secondary: SECONDARY,
};

export const MUI_THEME = createMuiTheme({
  palette: {
    primary: {
      light: fade(PRIMARY, 0.9),
      main: PRIMARY,
      dark: darken(PRIMARY, 0.1),
      contrastText: '#ffffff',
    },
    secondary: {
      light: fade(SECONDARY, 0.9),
      main: SECONDARY,
      dark: darken(SECONDARY, 0.1),
      contrastText: '#ffffff',
    },
    text: {
      primary: BLACK_TEXT,
      secondary: fade(BLACK_TEXT, 0.6),
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    subtitle1: { fontSize: '16px', fontWeight: 'bold', lineHeight: '24px', letterSpacing: 0 },
    subtitle2: { fontSize: '14px', fontWeight: 'bold', lineHeight: '20px', letterSpacing: 0 },
    body1: { fontSize: '16px', lineHeight: '24px', fontWeight: 'normal', letterSpacing: 0 },
    body2: { fontSize: '14px', lineHeight: '20px', fontWeight: 'normal', letterSpacing: 0 },
    caption: { fontSize: '12px', lineHeight: '20px', letterSpacing: 0 },
    button: {
      fontSize: '16px',
      textTransform: 'none',
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: 0,
    },
    h1: { fontWeight: 300, fontSize: '96px', lineHeight: '144px', letterSpacing: 0 },
    h2: { fontWeight: 300, fontSize: '60px', lineHeight: '90px', letterSpacing: 0 },
    h3: { fontWeight: 'normal', fontSize: '48px', lineHeight: '72px', letterSpacing: 0 },
    h4: { fontWeight: 'normal', fontSize: '34px', lineHeight: '52px', letterSpacing: 0 },
    h5: { fontWeight: 500, fontSize: '24px', lineHeight: '36px', letterSpacing: 0 },
    h6: { fontWeight: 500, fontSize: '20px', lineHeight: '32px', letterSpacing: 0 },
  },
  overrides: {
    MuiPaper: {
      outlined: {
        border: `1px solid ${LIGHT_GREY}`,
      },
      elevation1: {
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05)',
      },
      elevation2: {
        boxShadow: ' 3px 3px 6px rgba(0, 0, 0, 0.05), -3px -3px 6px rgba(0, 0, 0, 0.05)',
      },
      elevation3: {
        boxShadow: '5px 5px 9px rgba(0, 0, 0, 0.05), -5px -5px 9px rgba(0, 0, 0, 0.05)',
      },
      elevation4: {
        boxShadow: '5px 5px 16px rgba(0, 0, 0, 0.05), -5px -5px 16px rgba(0, 0, 0, 0.05)',
      },
      elevation5: {
        boxShadow: '5px 5px 24px rgba(0, 0, 0, 0.05), -5px -5px 24px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiButton: {
      sizeLarge: {
        height: '40px',
        padding: '0 9px',
        fontWeight: 'bold',
      },
      sizeSmall: {
        height: '30px',
        padding: '0 9px',
        fontWeight: 500,
      },
    },
  },
});
