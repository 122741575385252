import { Button, InputAdornment, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'typesafe-actions';
import voca from 'voca';
import { GREY } from '../../../../configs/colors';
import { SUCCESS_CODE, invalidUserNameRegex, validEmailRegex } from '../../../../constants';
import { AppState } from '../../../../redux/reducers';
import { ReactComponent as IconProfile } from '../../../../svg/ic_profile.svg';
import { Col, Row, snackbarSetting } from '../../../common/components/elements';
import { FreeTextField } from '../../../common/components/Form';
import { forgotPassWord } from '../../redux/authThunks';
import { Phase } from '../../utils';

interface Props {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
  onChangePage(page: Phase): void;
  onChange(username: string): void;
  username: string;
  style?: React.CSSProperties;
}

const ForgotPasswordComponent = (props: Props) => {
  const { onChange, onChangePage, username, style, dispatch } = props;
  const [validation, setValidation] = React.useState<boolean>(true);
  const [ticktock, setTick] = React.useState(false);
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getOTP = React.useCallback(async () => {
    const json = await dispatch(forgotPassWord(username));
    if (json?.code === SUCCESS_CODE) {
      onChangePage('setupPassword');
    }
    json?.message &&
      enqueueSnackbar(
        json?.message,
        snackbarSetting(key => closeSnackbar(key), json?.message),
      );
  }, [closeSnackbar, dispatch, enqueueSnackbar, onChangePage, username]);

  const validateParams = React.useCallback(() => {
    const valid = username ? !!username.trim() : false;
    const usernameValid = username ? validEmailRegex.test(username) : false;

    setValidation(false);
    let errorMessage;
    if (!usernameValid) {
      errorMessage = 'auth.error.usernameValid';
    }
    if (!valid) {
      errorMessage = 'auth.error.username';
    }
    setTick(one => !one);
    if (errorMessage) {
      enqueueSnackbar(
        errorMessage,
        snackbarSetting(key => closeSnackbar(key), intl.formatMessage({ id: errorMessage })),
      );
    } else {
      setValidation(true);
      getOTP();
    }
  }, [closeSnackbar, enqueueSnackbar, getOTP, intl, username]);

  return (
    <Col key={`${ticktock}`} style={{ ...style, width: 600 }}>
      <Col style={{ padding: '22px 24px 16px 24px' }}>
        <Typography variant="h6">
          <FormattedMessage id="forgotPassword" />
        </Typography>
        <Typography variant="subtitle2" style={{ margin: '16px 0px' }}>
          <FormattedMessage id="auth.changePasswordByUserName" />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="auth.forgotPasswordNote" />
        </Typography>
        <form onSubmit={validateParams}>
          <FreeTextField
            key={`${ticktock}`}
            style={{ marginTop: 8, marginRight: 0 }}
            header={intl.formatMessage({ id: 'userName' })}
            placeholder={intl.formatMessage({ id: 'userNameEx' })}
            text={username}
            valid={validation}
            update={text => {
              !invalidUserNameRegex.test(text) && onChange(voca.latinise(text));
            }}
            inputProps={{ style: { minHeight: 40 }, maxLength: 50 }}
            endAdornment={
              <InputAdornment position="end" style={{ marginRight: 8 }}>
                <IconProfile />
              </InputAdornment>
            }
            optional
          />

          <Row style={{ marginTop: 48, justifyContent: 'center' }}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              style={{ marginRight: 24, minWidth: 144 }}
              onClick={validateParams}
            >
              <Typography variant="button">
                <FormattedMessage id="sendRequest" />
              </Typography>
            </Button>
            <Button
              disableElevation
              variant="contained"
              size="large"
              style={{ minWidth: 144, background: GREY, color: 'white' }}
              onClick={() => onChangePage('login')}
            >
              <Typography variant="button">
                <FormattedMessage id="back" />
              </Typography>
            </Button>
          </Row>
        </form>
      </Col>
    </Col>
  );
};

export default connect()(ForgotPasswordComponent);
