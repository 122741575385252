import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import { fetchThunk } from '../../../../common/redux/thunk';
import { IPermissionGroup } from '../../../../common/models/userPermission';
import { PermissionFilterParams, defaultPermissionFilter } from '../../utils';
import { removeNullParams } from '../../../constants';

export interface ManagerPermissionState {
  data?: some;
  filter: PermissionFilterParams;
  permissionGroupDetail?: IPermissionGroup;
  loading: boolean;
}

export const setData = createAction('smartPos/managerPermission/setData', (data?: some) => ({
  data,
}))();

export const setLoading = createAction('smartPos/managerPermission/setLoading', (val: boolean) => ({
  val,
}))();

export const setFilter = createAction(
  'smartPos/managerPermission/setFilter',
  (data: PermissionFilterParams) => ({
    data,
  }),
)();

export const setPermissionGroupDetail = createAction(
  'smartPos/managerPermission/setPermissionGroupDetail',
  (data: IPermissionGroup) => ({
    data,
  }),
)();

export function fetchRoleGroup(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { filter } = state.smartPosSo.managerUserPermission.managerPermissionState;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getRoleGroup, 'post', JSON.stringify(removeNullParams(filter))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

const actions = { setData, setFilter, setLoading, setPermissionGroupDetail };

export const DEFAULT_MANAGER_PERMISSION_STATE: ManagerPermissionState = {
  filter: defaultPermissionFilter,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerPermissionState = DEFAULT_MANAGER_PERMISSION_STATE,
  action: ActionT,
): ManagerPermissionState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setFilter):
      return { ...state, filter: action.payload.data };
    case getType(setPermissionGroupDetail):
      return { ...state, permissionGroupDetail: action.payload.data };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
