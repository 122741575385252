import { createAction, getType, Action } from 'typesafe-actions';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import accountReducer, {
  AccountState,
  DEFAULT_ACCOUNT_STATE,
} from '../modules/account/redux/accountReducer';
import authReducer, { AuthState, DEFAULT_AUTH_STATE } from '../modules/auth/redux/authReducer';
import commonReducer, { CommonState, DEFAULT_COMMON_STATE } from '../modules/common/redux/reducer';
import intlReducer, { IntlState } from '../modules/intl/redux/reducer';
import smartPosSoReducer, {
  SmartPosSoState,
  DEFAULR_SMARTPOS_SO_STATE,
} from '../modules/smart-pos-so/redux/reducer';

export const clearStoreAfterLogout = createAction('clearStoreAfterLogout')();

const persistConfig: PersistConfig<any> = {
  storage,
  key: 'root',
  transforms: [],
  blacklist: ['router', 'intl'],
};

export interface AppState {
  router: RouterState;
  intl: IntlState;
  common: CommonState;
  auth: AuthState;
  account: AccountState;
  smartPosSo: SmartPosSoState;
}

export default (history: History) => {
  const rawRootReducer = combineReducers({
    router: connectRouter(history),
    intl: intlReducer,
    common: commonReducer,
    auth: authReducer,
    account: accountReducer,
    smartPosSo: smartPosSoReducer,
  });

  return persistReducer(
    persistConfig,
    (state: AppState | undefined, action: Action<string>): AppState => {
      if (state && action.type === getType(clearStoreAfterLogout)) {
        return rawRootReducer(
          {
            ...state,
            common: DEFAULT_COMMON_STATE,
            auth: DEFAULT_AUTH_STATE,
            account: DEFAULT_ACCOUNT_STATE,
            smartPosSo: DEFAULR_SMARTPOS_SO_STATE,
          },
          action,
        );
      }
      return rawRootReducer(state, action);
    },
  );
};
