import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import { fetchThunk } from '../../../../common/redux/thunk';
import { removeNullParams } from '../../../constants';
import { DeviceFilterParams, defaultDeviceFilterParams } from '../../../../common/models/device';

export interface managerStatusState {
  data?: some;
  params: DeviceFilterParams;
  loading: boolean;
}

export const setData = createAction('smartPos/managerStatusDevice/setData', (data?: some) => ({
  data,
}))();

export const setParams = createAction(
  'smartPos/managerStatusDevice/setParams',
  (params: DeviceFilterParams) => ({
    params,
  }),
)();

export const setLoading = createAction(
  'smartPos/managerStatusDevice/setLoading',
  (val: boolean) => ({
    val,
  }),
)();

export function fetchMangeDeviceData(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { params } = state.smartPosSo.configurationDevice.managerStatus;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getDevice, 'post', JSON.stringify(removeNullParams(params))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

const actions = { setData, setParams, setLoading };
export const DEFAULT_MANAGER_STATUS_STATE: managerStatusState = {
  params: defaultDeviceFilterParams,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: managerStatusState = DEFAULT_MANAGER_STATUS_STATE,
  action: ActionT,
): managerStatusState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setParams):
      return { ...state, params: action.payload.params };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
