import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../constants';
import { AppState } from '../../../../redux/reducers';
import { fetchThunk } from '../../../common/redux/thunk';
import { removeNullParams } from '../../constants';
import { defaultFilterPaymentParams, FilterPaymentParams, PaymentMethodInfo } from '../utils';

export interface ManagerPaymentMethodState {
  data?: some;
  params: FilterPaymentParams;
  loading: boolean;
}

export const setData = createAction('smartPos/managerPaymentMethod/setData', (data?: some) => ({
  data,
}))();

export const setParams = createAction(
  'smartPos/managerPaymentMethod/setParams',
  (params: FilterPaymentParams) => ({
    params,
  }),
)();

export const setLoading = createAction(
  'smartPos/managerPaymentMethod/setLoading',
  (val: boolean) => ({
    val,
  }),
)();

export function fetchManagerPaymentMethodData(): ThunkAction<
  Promise<some>,
  AppState,
  null,
  Action<string>
> {
  return async (dispatch, getState) => {
    const state = getState();
    const { params } = state.smartPosSo.managementPaymentMethod;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getPaymentMethod, 'post', JSON.stringify(removeNullParams(params))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

export function onUpdatePaymentMethod(
  info: PaymentMethodInfo,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(
      fetchThunk(API_PATHS.updatePaymentMethod, 'post', JSON.stringify(info)),
    );
    return json;
  };
}

export function onCreatePaymentMethod(
  info: PaymentMethodInfo,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(fetchThunk(API_PATHS.addDevice, 'post', JSON.stringify(info)));
    return json;
  };
}

const actions = { setData, setParams, setLoading };
export const DEFAULT_MANAGER_PAYMENT_STATE: ManagerPaymentMethodState = {
  params: defaultFilterPaymentParams,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerPaymentMethodState = DEFAULT_MANAGER_PAYMENT_STATE,
  action: ActionT,
): ManagerPaymentMethodState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setParams):
      return { ...state, params: action.payload.params };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
