import React, { useEffect, FC } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { BACKGROUND } from '../../../configs/colors';
import { AppState } from '../../../redux/reducers';
import { Col } from '../../common/components/elements';
import LoadingIcon from '../../common/components/LoadingIcon';
import LoginBox from '../components/LoginBox';
import { validateAccessToken } from '../redux/authThunks';

export const PageWrapper = styled.div`
  min-height: ${window.innerHeight}px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state: AppState) => ({ validatingToken: state.auth.validatingToken });
interface LoginProps extends ReturnType<typeof mapStateToProps> {
  dispatch: ThunkDispatch<AppState, null, Action<string>>;
}

const Login: FC<LoginProps> = props => {
  const { dispatch, validatingToken } = props;

  useEffect(() => {
    dispatch(validateAccessToken());
  }, [dispatch]);

  return (
    <PageWrapper
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: BACKGROUND,
      }}
    >
      <div style={{ flex: 1 }} />
      <Col
        style={{
          background: 'white',
          boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.1)',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 30,
        }}
      >
        {validatingToken ? <LoadingIcon /> : <LoginBox />}
      </Col>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ flex: 1, display: 'flex', alignItems: 'flex-end', marginBottom: 28 }}
      >
        <FormattedMessage id="companyName" />
      </Typography>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(Login);
