import { some } from '../../constants';

export const userOptionsStatus = [
  {
    label: 'active',
    id: true,
  },
  {
    label: 'deActive',
    id: false,
  },
]

export const deviceOptionsStatus = [
  {
    label: 'all',
    id: -1,
  },
  {
    label: 'active',
    id: 1,
  },
  {
    label: 'deActive',
    id: 0,
  },
]

export const optionsStatus = [
  {
    label: 'managerBanner.assigned',
    id: 1,
  },
  {
    label: 'managerBanner.notAssigned',
    id: 0,
  },
]

export const optionsStatusDevice = [
  {
    label: 'all',
    id: -1,
  },
  ...optionsStatus
];

export const optionsConnectDevice = [
  {
    label: 'all',
    id: -1,
  },
  {
    label: 'connect',
    id: 1,
  },
  {
    label: 'disconnect',
    id: 0,
  },
];

export const optionsStatusPaperDevice = [
  {
    label: 'all',
    id: -1,
  },
  {
    label: 'availablePaper',
    id: 0,
  },
  {
    label: 'unAvailablePaper',
    id: 2,
  },
  {
    label: 'beingOutOfPaper',
    id: 3,
  },
];

export const optionPaymentStatus = [
  {
    label: 'all',
    id: '-1',
  },
  {
    label: 'payment.success',
    id: 'success',
  },
  {
    label: 'payment.failed',
    id: 'failed',
  },
  {
    label: 'payment.pending',
    id: 'pending',
  },
];

export const optionPaymentMethod = [
  {
    label: 'all',
    id: '-1',
  },
  {
    label: 'managerTransaction.pos',
    id: 'pos',
  },
  {
    label: 'managerTransaction.qrCode',
    id: 'qr',
  },
]

export function removeNullParams(params: some, removeValueAll: boolean = false) {
  const temp = { ...params };

  Object.keys(params).forEach(key => {
    if (params[key] === -1 || params[key] === '' || params[key] === '-1') {
      delete temp[key];
    }
  });

  return temp;
}
