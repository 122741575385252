import { combineReducers } from 'redux';

import managerBannerGroupSOReducer, {
  ManagerBannerGroupSOState, DEFAULT_MANAGER_BANNER_SO_STATE,
} from '../bannerGroupSO/redux/managerBannerGroupSOReducer';
import managerBannerGroupReducer, {
  ManagerBannerGroupState, DEFAULT_MANAGER_BANNER_GROUP_STATE,
} from '../bannerGroup/redux/managerBannerGroupReducer';

export const DEFAULT_MANAGER_BANNER_STATE:ManagerBannerState={
  managerBannerGroup: DEFAULT_MANAGER_BANNER_GROUP_STATE,
  managerBannerGroupSO: DEFAULT_MANAGER_BANNER_SO_STATE
}
export interface ManagerBannerState {
  managerBannerGroup: ManagerBannerGroupState;
  managerBannerGroupSO: ManagerBannerGroupSOState;
}

export default combineReducers({
  managerBannerGroup: managerBannerGroupReducer,
  managerBannerGroupSO: managerBannerGroupSOReducer,
});
