import { keyframes } from 'styled-components';

export const IMAGE_ALLOW_TYPE = 'image/jpeg, image/png, image/gif , .svg';
export const FILE_ALLOW_TYPE = '.csv';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_BACKEND = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_BACKEND_FORMAT = `${DATE_FORMAT_BACKEND} ${TIME_FORMAT}`;

export const VIBRATE_KEYFRAME = keyframes`
  0%{
    left: -8px;
  }
  50%{
    left: 8px;
  }
  100% {
    left: -8px;
  }
`;
