import React from 'react';
import { ReactComponent as IconDevice } from '../../svg/ic_local_gray.svg';
import { ReactComponent as IconSetting } from '../../svg/ic_setting.svg';
import { ReactComponent as IconPay } from '../../svg/ic_pay.svg';
import { ReactComponent as IconPerson } from '../../svg/ic_person.svg';
import { ReactComponent as IconImage } from '../../svg/ic_image.svg';
import { ReactComponent as IconRefund } from '../../svg/ic_refund.svg';
import '../../scss/svg.scss';
import { SECONDARY } from '../../configs/colors';

export const listIcon = (open: boolean) => {
  return [
    {
      name: 'managerDevice',
      icon: (
        <IconDevice
          className="svgFillAll"
          style={{ stroke: !open ? SECONDARY : 'white', width: 24, height: 24 }}
        />
      ),
    },
    {
      name: 'managerBanner',
      icon: (
        <IconImage
          className="svgFillAll"
          style={{ stroke: !open ? SECONDARY : 'white', width: 24, height: 24 }}
        />
      ),
    },
    {
      name: 'configurationDevice',
      icon: (
        <IconSetting
          className="svgFillAll"
          style={{ stroke: !open ? SECONDARY : 'white', width: 24, height: 24 }}
        />
      ),
    },
    {
      name: 'paymentMethod',
      icon: (
        <IconPay
          className="svgFillAll"
          style={{ stroke: !open ? SECONDARY : 'white', width: 24, height: 24 }}
        />
      ),
    },
    {
      name: 'managerTransaction',
      icon: (
        <IconRefund
          className="svgFillAll"
          style={{ stroke: !open ? SECONDARY : 'white', width: 24, height: 24 }}
        />
      ),
    },
    {
      name: 'managerUserPermission',
      icon: (
        <IconPerson
          className="svgFillAll"
          style={{ stroke: !open ? SECONDARY : 'white', width: 24, height: 24 }}
        />
      ),
    },
  ];
};

interface Props {
  name: string;
  open: boolean;
}

const DefaultAsideItemsIcon: React.FC<Props> = (props: Props) => {
  const { name, open } = props;
  const getIcon = React.useMemo(() => {
    return listIcon(open).find(v => v.name === name);
  }, [name, open]);

  return <>{getIcon?.icon}</>;
};

export default DefaultAsideItemsIcon;
