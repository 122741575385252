import { ThunkAction } from 'redux-thunk';
import { Action, ActionType, createAction, getType } from 'typesafe-actions';
import { API_PATHS } from '../../../../../configs/API';
import { some, SUCCESS_CODE } from '../../../../../constants';
import { AppState } from '../../../../../redux/reducers';
import { fetchThunk } from '../../../../common/redux/thunk';
import { removeNullParams } from '../../../constants';
import { defaultUserFilter, UserFilterParams } from '../../utils';

export interface ManagerUserState {
  filter: UserFilterParams;
  data?: some;
  userDetail?: some;
  allRoleGroupData: some[];
  loading: boolean;
}

export const setData = createAction('smartPos/managerUser/setData', (data?: some) => ({
  data,
}))();

export const setFilter = createAction(
  'smartPos/managerUser/setFilter',
  (data: UserFilterParams) => ({
    data,
  }),
)();

export const setUserDetail = createAction('smartPos/managerUser/setUserDetail', (data: some) => ({
  data,
}))();

export const setRoleGroupData = createAction(
  'smartPos/managerUser/setRoleGroupData',
  (data: some[]) => ({
    data,
  }),
)();

export const setLoading = createAction('smartPos/managerUser/setLoading', (val: boolean) => ({
  val,
}))();

export function fetchAllRoleGroup(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const json = await dispatch(fetchThunk(API_PATHS.getAllRoleGroup));
    if (json?.code === SUCCESS_CODE) {
      dispatch(setRoleGroupData(json.data));
    }
    return json;
  };
}

export function fetchGetListUser(): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const { filter } = state.smartPosSo.managerUserPermission.managerUserState;
    dispatch(setLoading(true));
    const json = await dispatch(
      fetchThunk(API_PATHS.getListUser, 'post', JSON.stringify(removeNullParams(filter))),
    );
    if (json?.code === SUCCESS_CODE) {
      dispatch(setData(json.data));
    }
    dispatch(setLoading(false));
    return json;
  };
}

const actions = { setData, setUserDetail, setFilter, setRoleGroupData, setLoading };

export const DEFAULT_MANAGER_USER_STATE: ManagerUserState = {
  allRoleGroupData: [],
  filter: defaultUserFilter,
  loading: false,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: ManagerUserState = DEFAULT_MANAGER_USER_STATE,
  action: ActionT,
): ManagerUserState {
  switch (action.type) {
    case getType(setData):
      return { ...state, data: action.payload.data };
    case getType(setFilter):
      return { ...state, filter: action.payload.data };
    case getType(setUserDetail):
      return { ...state, userDetail: action.payload.data };
    case getType(setRoleGroupData):
      return { ...state, allRoleGroupData: action.payload.data };
    case getType(setLoading):
      return { ...state, loading: action.payload.val };
    default:
      return state;
  }
}
