import { PAGE_SIZE } from '../../../constants';

export interface FilterPaymentParams {
  code: string;
  name: string;
  status: number;
  pageSize: number;
  pageOffset: number;
}
export const defaultFilterPaymentParams: FilterPaymentParams = {
  code: '',
  name: '-1',
  status: -1,
  pageSize: PAGE_SIZE,
  pageOffset: 0,
};

export interface PaymentMethodInfo {
  code: string;
  name: string;
  id?: number;
  deviceTypeId?: number;
  description: string;
  status?: number;
}

export const defaultPaymentMethodInfo: PaymentMethodInfo = {
  code: '',
  name: '',
  description: '',
};
export interface PaymentMethodInfoValidation {
  code: boolean;
  name: boolean;
}
export const defaultPaymentMethodInfoValidation: PaymentMethodInfoValidation = {
  code: true,
  name: true,
};
export function validationPaymentMethodInfo(info: PaymentMethodInfo): PaymentMethodInfoValidation {
  const code = info.code ? !!info.code.trim() : false;
  const name = info.name ? !!info.name.trim() : false;

  return { ...defaultPaymentMethodInfoValidation, code, name };
}

export function validPaymentMethodInfo(validation: PaymentMethodInfoValidation) {
  if (!validation.code) {
    return 'managerPaymentMethod.error.code';
  }
  if (!validation.name) {
    return 'managerPaymentMethod.error.name';
  }
  return '';
}
