import { GAMI } from "../constants";
import colors from '../scss/colors.module.scss';

export const BACKGROUND = colors.background;
const { primary, secondary, gamiPrimary, gamiSecondary} = colors;

export const PRIMARY = primary;
export const SECONDARY = GAMI ? gamiSecondary : secondary;
export const ASIDE_BACKGROUND = '#4F4F4F';
export const BLUE = '#0D66D0';
export const LIGHT_BLUE = '#1976D2';
export const GREEN = '#2D9D78';
export const DARK_GREY = '#757575';
export const GREY = '#BDBDBD';
export const LIGHT_GREY = '#F5F5F5';
export const FOOTER_GREY = '#828282';
export const BLACK_TEXT = '#212121';
export const WHITE = '#FFFFFF';
export const RED = '#F44336';
export const SECOND_GREY = '#E0E0E0';
export const DARK_ORANGE = '#F96333';
export const DATE_TEXT_BLACK = '#484848';

//
export const BORDER_GREY = '#BDBDBD';
export const DARK_BLUE = '#050843';
export const DARK_GREEN = '#00ACA0';
export const BROWN = '#964B00';
