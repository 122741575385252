import { ActionType, createAction, getType, Action } from 'typesafe-actions';
import { ThunkAction } from 'redux-thunk';
import { some } from '../../../constants';
import { fetchThunk } from '../../common/redux/thunk';
import { API_PATHS } from '../../../configs/API';
import { AppState } from '../../../redux/reducers';

export interface AccountState {
  readonly userData?: some;
}

export const setUserData = createAction('account/setUserData', (data?: some) => ({ data }))();

export function onUpdateAccount(
  info: some,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async dispatch => {
    const json = await dispatch(fetchThunk(API_PATHS.updateUser, 'post', JSON.stringify(info)));
    return json;
  };
}

export const DEFAULT_ACCOUNT_STATE = {};
const actions = {
  setUserData,
};

type ActionT = ActionType<typeof actions>;

export default function reducer(
  state: AccountState = DEFAULT_ACCOUNT_STATE,
  action: ActionT,
): AccountState {
  switch (action.type) {
    case getType(setUserData):
      return { ...state, userData: action.payload.data };
    default:
      return state;
  }
}
